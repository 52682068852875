import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCampaignTags } from '@app/store';
import { AppState } from '@store/app.state';
import { DialogRef } from '@ngneat/dialog';
import { CONFIG } from '@config/configuration';
import { PostModel } from '@app/shared/model';
import SwiperCore, { EffectFade, Navigation } from 'swiper';
import { Observable } from 'rxjs';

SwiperCore.use([Navigation, EffectFade]);

@Component({
  selector: 'app-post-editing-dialog',
  templateUrl: './post-editing-dialog.component.html',
})
export class PostEditingDialogComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() postData: any;
  @Output() isEditingChange = new EventEmitter<boolean>();
  @Input() editingHistory: any;
  @Input() feedbacksHistory: any;
  campaignTags$: Observable<any>;
  post: PostModel;
  profileImage: string;

  config = {
    navigation: {
      nextEl: '.carousel-next',
      prevEl: '.carousel-previous',
    },
  };

  constructor(public ref: DialogRef, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.post = this.postData || this.ref.data.post;
    this.campaignTags$ = this.store.pipe(select(selectCampaignTags));
    this.profileImage = this.post.socialAccountInfo.profilePicture;
  }

  setDefaultProfileImage(): void {
    this.profileImage = 'assets/img/icons/user-default.png';
  }

  getIva(postPriceTechFee: number): number {
    const IVA = +(postPriceTechFee * CONFIG.platform?.iva).toFixed(2);
    return IVA;
  }
}
