import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'app-post-review-change',
  templateUrl: './post-review-change.component.html',
})
export class PostReviewChangeComponent implements OnInit {
  feedbackText: string;

  @ViewChild('feedback') set feedbackTextRef(ref: ElementRef) {
    if (!!ref) {
      ref.nativeElement.focus();
    }
  }

  constructor(public ref: DialogRef) {}

  ngOnInit(): void {
    this.feedbackText = this.ref.data.feedback;
  }
}
