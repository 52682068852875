export class SocialAccountModel {
  accessToken: string;
  followers: number;
  id: number;
  instagramId: number;
  isBusiness?: boolean;
  name: string;
  profilePicture: string;
  username: string;
  engagementRate?: number;
  profileUrl?: string;
}
