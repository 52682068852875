import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { Observable, Subject } from 'rxjs';
import { selectBrand, selectUserInfo, updateBrandInfo, updateUserInfo } from '@app/store';
import { BrandModel, UserModel } from '@app/shared/model';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as userActions from '@app/store/user';
import * as brandActions from '@app/store/brand';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@ngneat/dialog';
import { ChangePasswordComponent } from '@app/dialogs/change-password/change-password.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profile$: Observable<UserModel>;
  destroyed$ = new Subject<boolean>();
  agencyEnabled = false;
  invoiceEnabled = false;
  editPersonalInfo = false;
  editBillingInfo = false;
  userProfile: UserModel;
  brand: BrandModel;
  userId: number;
  brandId: number;
  isAgency: boolean;
  isInvoice: boolean;

  personalDataForm = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      company: [null, [Validators.required]],
      email: [null, [Validators.required]],
      phone: [null],
      mobile: [null],
    },
    {
      updateOn: 'change',
    }
  );

  billingDataForm = this.fb.group(
    {
      billingCompany: [null, [Validators.required]],
      streetAddress: [null, [Validators.required]],
      locationCity: [null, [Validators.required]],
      locationCountry: [null, [Validators.required]],
      locationState: [null, [Validators.required]],
      locationPostCode: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  taxDataForm = this.fb.group(
    {
      iva: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  ccEmailForm = this.fb.group(
    {
      ccEmail: [null, [Validators.required]],
    },
    {
      updateOn: 'change',
    }
  );

  firstNameInput = this.personalDataForm.get('firstName');
  lastNameInput = this.personalDataForm.get('lastName');
  companyInput = this.personalDataForm.get('company');
  emailInput = this.personalDataForm.get('email');
  phoneInput = this.personalDataForm.get('phone');
  mobileInput = this.personalDataForm.get('mobile');

  billingCompanyInput = this.billingDataForm.get('billingCompany');
  streetAddressInput = this.billingDataForm.get('streetAddress');
  locationCityInput = this.billingDataForm.get('locationCity');
  locationCountryInput = this.billingDataForm.get('locationCountry');
  locationStateInput = this.billingDataForm.get('locationState');
  locationPostCodeInput = this.billingDataForm.get('locationPostCode');
  ivaCodeInput = this.taxDataForm.get('iva');
  ccEmailInput = this.ccEmailForm.get('ccEmail');

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private actions: Actions,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.personalDataForm.controls.email.disable();

    this.selectUser();
    this.selectBrand();
    this.userInfoUpdateSuccess();
    this.userInfoUpdateFail();
  }

  userInfoUpdateSuccess(): void {
    const userInfoUpdateSuccessMsg = this.translate.instant('myProfile.userInfoUpdateSuccessMsg');

    this.actions
      .pipe(ofType(userActions.updateUserInfoSuccess, brandActions.updateBrandInfoSuccess), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.toastr.success(userInfoUpdateSuccessMsg);
        this.resetEditState();
      });
  }

  userInfoUpdateFail(): void {
    const userInfoUpdateFailMsg = this.translate.instant('myProfile.userInfoUpdateFailMsg');

    this.actions
      .pipe(ofType(userActions.updateUserInfoFail, brandActions.updateBrandInfoFail), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.toastr.error(userInfoUpdateFailMsg);
        this.resetEditState();
      });
  }

  selectUser(): void {
    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.personalDataForm.patchValue(user, { emitEvent: false });
        this.userId = user.id;
        this.isInvoice = user.invoice;
      }
    });
  }

  resetEditState(): void {
    this.editPersonalInfo = false;
    this.editBillingInfo = false;
  }

  selectBrand(): void {
    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
      this.personalDataForm.patchValue(brand, { emitEvent: false });
      this.billingDataForm.patchValue(brand, { emitEvent: false });
      this.taxDataForm.patchValue(brand, { emitEvent: false });
      this.ccEmailForm.patchValue(brand, { emitEvent: false });
      this.brandId = brand.id;
      this.isAgency = brand.isAgency;
    });
  }

  changeInvoice(): void {
    this.isInvoice = !this.isInvoice;
    const user = { invoice: this.isInvoice, id: this.userId };
    this.store.dispatch(updateUserInfo({ user }));
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      closeButton: true,
    });
  }

  changeAgency(): void {
    this.isAgency = !this.isAgency;
    const brand = { isAgency: this.isAgency, id: this.brandId };
    this.store.dispatch(updateBrandInfo({ brand }));
  }

  saveBrandInfo(): void {
    const { userId } = this;
    const user = {
      firstName: this.firstNameInput.value,
      lastName: this.lastNameInput.value,
      id: userId,
      invoice: this.isInvoice,
    } as Partial<UserModel>;

    const brand = {
      id: this.brandId,
      phone: this.phoneInput.value,
      mobile: this.mobileInput.value,
      company: this.companyInput.value,
      billing_company: this.billingCompanyInput.value,
      location_city: this.locationCityInput.value,
      location_state: this.locationStateInput.value,
      location_postcode: this.locationPostCodeInput.value,
      street_address: this.streetAddressInput.value,
      location_country: this.locationCountryInput.value,
      cc_email: this.ccEmailInput.value,
      is_agency: this.isAgency,
      iva: this.ivaCodeInput.value,
    };

    if (this.personalDataForm.valid && this.billingDataForm.valid) {
      this.store.dispatch(updateUserInfo({ user }));
      this.store.dispatch(updateBrandInfo({ brand }));
    }
  }
  cancelEditInfoChanges(): void {
    this.editPersonalInfo = false;
    this.editBillingInfo = false;
    this.selectUser();
    this.selectBrand();
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
