import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { getUser, selectUserInfo } from '@app/store';
import { AuthService } from '@app/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { take } from 'rxjs/operators';
import { UserModel } from '@app/shared/model';
import { ChangePasswordComponent } from '@app/dialogs/change-password/change-password.component';
import { DialogService } from '@ngneat/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, private store: Store<AppState>, private dialog: DialogService) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.store.dispatch(getUser());
    }

    this.store.pipe(select(selectUserInfo), take(2)).subscribe((user: UserModel) => {
      if (user && user.isAdditionalBrandUser && user.isFirstLogin) {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
          enableClose: false,
          closeButton: false,
        });
      }
    });
  }
}
