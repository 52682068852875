import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignModel } from '@app/shared/model';

export const getCampaignsFeatureState = createFeatureSelector<CampaignModel[]>('campaigns');

export const selectCampaigns = createSelector(getCampaignsFeatureState, (state: CampaignModel[]) => state);

export const getCampaign = createSelector(
  selectCampaigns,
  (state, { id }) => state.filter((campaign) => campaign.id === id)[0]
);

export const selectFilteredCampaigns = (name: string) =>
  createSelector(selectCampaigns, (campaigns: Array<CampaignModel>) => {
    return name && campaigns.filter((campaign) => campaign.campaignName.toLowerCase().includes(name.toLowerCase()));
  });

export const selectCampaignsByInterests = (interests: string[]) =>
  createSelector(selectCampaigns, (campaigns: Array<CampaignModel>) => {
    return campaigns.filter((campaign) => {
      return campaign.interests.some((interest) => interests.includes(interest));
    });
  });
