import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-private-influencers',
  templateUrl: './private-influencers.component.html',
})
export class PrivateInfluencersComponent implements OnInit {
  @Input() influencers: any[];
  @Output() influencerUpdate = new EventEmitter();
  @Input() selected: string[];

  form: FormGroup;
  allSelected = false;
  constructor(private fb: FormBuilder) {}

  onChange(): void {
    const arr = [];
    const values = this.form.value.influencers;
    values.forEach((value: boolean, i: number) => {
      if (value) {
        arr.push(this.influencers[i].id);
      }
    });
    this.checkAllSelected();
    this.influencerUpdate.emit(arr);
  }

  onSelectAll(e): void {
    e.preventDefault();
    if (!this.allSelected) {
      this.form = this.fb.group({
        influencers: this.fb.array(this.influencers.map((x) => !!1)),
      });
      this.allSelected = true;
    } else {
      this.form = this.fb.group({
        influencers: this.fb.array(this.influencers.map((x) => !1)),
      });
      this.allSelected = false;
    }

    this.onChange();
  }

  checkAllSelected(): void {
    const arrayControlValues = this.form.get('influencers').value;
    if (arrayControlValues.filter((x) => x).length === this.influencers.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      influencers: this.fb.array(
        this.influencers.map((x) => {
          if (this.selected.includes(x.id)) {
            return true;
          }

          return false;
        })
      ),
    });
  }
}
