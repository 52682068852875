import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AuthInterceptor } from '@app/services/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from '@app/common/not-found/not-found.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducer, appEffects, metaReducers } from '@app/store';
import { LandingModule } from './landing/landing.module';
import { CommonUIModule } from './common/common.module';
import { CampaignModule } from '@app/campaign/campaign.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { PolicyConditionsModule } from '@app/landing/policy-conditions/policy-conditions.module';
import { DialogModule } from '@ngneat/dialog';
import { PostFeedbackModule } from '@app/common/post-feedback/post-feedback.module';
import { ToastrModule } from 'ngx-toastr';
import { PostOverviewDialogModule } from '@app/dialogs/post-overview-dialog/post-overview-dialog.module';
import { cookieConfig } from '@app/cookie-config';
import { extModules } from '@app/build-specifics';
import { LoginModule } from '@app/landing/login/login.module';
import { FreeDemoModule } from '@app/landing/dialogs/free-demo/free-demo.module';
import { ForgotPasswordModule } from '@app/landing/dialogs/forgot-password/forgot-password.module';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { PostTabModule } from '@app/campaigns/campaign-overview/post-tab/post-tab.module';

const featureFactory = (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.loadConfig();

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    DialogModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    StoreModule.forRoot(appReducer, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    extModules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LandingModule,
    LoginModule,
    FreeDemoModule,
    ForgotPasswordModule,
    CommonUIModule,
    CampaignModule,
    // PolicyConditionsModule,
    PostFeedbackModule,
    PostOverviewDialogModule,
    PostTabModule,
    // MatSelectModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
