<div class="modal-block modal-brand-login" >
  <div class="modal__cont">
    <h3>{{'campaignOverview.post.dialog.change' | translate}}</h3>
    <div class="select-box">
      <label>
        <textarea #feedback class='feedback-change-text' [(ngModel)]='feedbackText'
          [placeholder]='"campaignOverview.post.dialog.changePlaceholder" | translate'>
        </textarea>
      </label>

      <button type="button" class="btn big primary-btn-filled" [disabled]='!feedbackText' [dialogClose]="feedbackText">
        <span class="btn__text">{{'campaignOverview.post.dialog.sendFeedbackBtn' | translate}}</span>
      </button>
    </div>
  </div>
</div>
