<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
  <ng-container [ngSwitch]="isPrivateInfluencersFeatureEnabled">
    <ng-container *ngSwitchCase="true">
      <ng-container *ngIf="isPrivate && privateInfluencers && privateInfluencers.length">
         <ng-container *ngTemplateOutlet="privateInfluencersTmp"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isPrivate" >
        <ng-container *ngTemplateOutlet="defaultFormTmp"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *NgSwitchDefault>
      <ng-container *ngTemplateOutlet="defaultFormTmp"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #privateInfluencersTmp>
    <app-private-influencers  [influencers]="privateInfluencers" (influencerUpdate)="onUpdateInfluencers($event)" [selected]="selectedInfluencers"></app-private-influencers>
  </ng-template>

  <ng-template #defaultFormTmp>
    <div class="new-campaign-influencers new-campaign-form__wrapper mt-50 mb-40">
      <div class='new-campaign-form__colum'>
        <div class='form__box'>
          <div class='form__title-group'>
            <div *ngIf='selectedSocialPlatform.length >= 2 && this.socialPlatform?.platformLinkedin' class='select-type-group'>
              <mat-tab-group>
                <mat-tab [label]="'newCampaign.socialMediaInterests' | translate">
                  <app-select-interests
                    [(selectInterests)]='interests'
                    [isTabs]='true'
                  ></app-select-interests>
                </mat-tab>
                <mat-tab [label]="'newCampaign.linkedinOnlyInterests' | translate">
                  <app-select-interests
                    [linkedinInterests]='linkedinInterests'
                    [(selectInterests)]='selectedLinkedinInterests'
                    [isTabs]='true'
                  ></app-select-interests>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div *ngIf='selectedSocialPlatform.length <= 1 && this.socialPlatform?.platformLinkedin'>
              <span class="form__title">{{'newCampaign.review.interests' | translate }}</span>
              <app-select-interests
                [linkedinInterests]='linkedinInterests'
                [(selectInterests)]='selectedLinkedinInterests'
              ></app-select-interests>
            </div>

            <div *ngIf='!this.socialPlatform?.platformLinkedin'>
              <span class="form__title">{{'newCampaign.review.interests' | translate }}</span>
              <app-select-interests
                [(selectInterests)]='interests'
              ></app-select-interests>
            </div>
          </div>
        </div>
      </div>

        <div class="new-campaign-form__colum">
          <div class="form__box">
            <span class="form__title">{{'newCampaign.review.age' | translate }}</span>
            <div class="new-campaign-form__slider">
                <ngx-slider [(value)]="age.ageMin" [(highValue)]="age.ageMax" [options]="options"></ngx-slider>
            </div>
          </div>

          <div class="form__box">
            <span class="form__title">{{'newCampaign.review.gender' | translate }}</span>

            <div class="new-campaign-form__btn-group">
              <button (click)="gender.female = !gender.female" type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : gender.female}'>
                <span class="btn__text">female</span>
              </button>

              <button (click)="gender.male = !gender.male" type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : gender.male}'>
                <span class="btn__text">male</span>
              </button>
            </div>
          </div>

          <div class="form__box">
            <span class="form__title">{{'newCampaign.review.demographic' | translate }}</span>

            <div class="new-campaign-form__btn-group">

              <button (click)='country.active = !country.active' [ngClass]='{"active" : country.active}' type="button" class="btn small campaign-secondary-btn" *ngFor='let country of targetRegion'>
                <span class="btn__text">{{country.country_name}}</span>
              </button>

            </div>
          </div>
        </div>
      </div>
  </ng-template>

</app-campaign-wizard-step>
