import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrandAdapter } from '@app/shared/adapters/brand.adapter';
import { BrandModel } from '@app/shared/model/brand.model';
import { environment } from '@env/environment';
import BrandDto from '@app/shared/dto/brand.dto';
import { CONFIG } from '@config/configuration';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getBrand(): Observable<BrandModel> {
    return this.http.get<any>(CONFIG.baseUrl + 'brands').pipe(
      map((res) => {
        const { brand } = res;
        return BrandAdapter.createBrand(brand[0]);
      })
    );
  }

  createBrand(brandModel: Partial<BrandModel>): Observable<BrandModel> {
    const brand = this.serializeBrand(brandModel);
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==', // TODO: refactor later, implemented the existing approach
    });
    return this.http.post<any>(CONFIG.baseUrl + 'brands', { brand }, { headers }).pipe(
      map((res) => {
        // tslint:disable-next-line:no-shadowed-variable
        const { brand } = res;
        return BrandAdapter.createBrand(brand);
      })
    );
  }

  serializeBrand(brand: Partial<BrandModel>): Partial<BrandDto> {
    const { company, mobile, origin, phone, iva } = brand;
    return {
      billing_company: brand.billingCompany,
      cc_email: brand.ccEmail,
      company,
      is_agency: brand.isAgency,
      iva,
      location_city: brand.locationCity,
      location_country: brand.origin || 'Italy',
      location_postcode: brand.locationPostCode,
      location_state: brand.locationState,
      mobile,
      origin,
      pec_univoco: brand.pecUnivoco,
      phone,
      platform_name: brand.platformName,
      street_address: brand.streetAddress,
      user: brand.user,
    };
  }

  updateBrandInfo(brandModel: Partial<BrandModel>): Observable<BrandModel> {
    const brand = {
      ...brandModel,
      is_agency: brandModel.isAgency,
      platform_name: CONFIG.platformName,
    };
    return this.http
      .patch<any>(this.baseUrl + `brands/${brand.id}`, { brand })
      .pipe(
        map((res) => {
          // tslint:disable-next-line:no-shadowed-variable
          const { brand } = res;
          return BrandAdapter.createBrand(brand);
        })
      );
  }
}
