import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { logOutUser } from '@store/app.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { selectBrand, selectUserInfo } from '@app/store';
import { BrandModel, UserModel } from '@app/shared/model';
import { DialogService } from '@ngneat/dialog';
import { CONFIG } from '@config/configuration';
import { InviteDialogComponent } from '@app/shared-features/invite-dialog/invite-dialog.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-authorized',
  templateUrl: './menu-authorized.component.html',
  styleUrls: ['./menu-authorized.component.scss'],
})
export class MenuAuthorizedComponent implements OnInit, AfterViewInit, OnDestroy {
  brand$: Observable<BrandModel>;
  openedMenu = false;
  socialLinks = CONFIG.platform.socialLinks;
  isFeedbackPage = false;
  isAdditionalUser = false;
  private destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private dialog: DialogService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.brand$ = this.store.pipe(select(selectBrand));
    this.router.events.subscribe(() => {
      this.isFeedbackPage = !!this.location.path().includes('services/feedback');
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement.querySelectorAll('.overlay-menu');
  }

  goToRoute(url: string): void {
    this.toggleMenu(false);
    this.router.navigate([`/${url}`]);
  }

  toggleMenu(close?: boolean): void {
    this.openedMenu = close === false ? false : !this.openedMenu;
    const element = this.elementRef.nativeElement.querySelectorAll('.overlay-menu');
    const display = this.openedMenu ? 'block' : 'none';
    this.renderer.setStyle(element[0], 'display', display);
  }

  openInviteDialog(): void {
    const dialogRefInvite = this.dialog.open(InviteDialogComponent, {
      closeButton: true,
    });
  }

  logout(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    this.store.dispatch(logOutUser());
    this.router.navigate(['/']);
  }
}
