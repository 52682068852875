import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitAuthorizedComponent } from './submit-authorized.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagDirectiveModule } from '@app/shared/directives/feature-flag-directive/feature-flag-directive.module';

@NgModule({
  declarations: [SubmitAuthorizedComponent],
  exports: [SubmitAuthorizedComponent],
  imports: [
    CommonModule,
    CampaignWizardModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FeatureFlagDirectiveModule,
  ],
})
export class SubmitAuthorizedModule {}
