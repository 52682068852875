<div class="private-influencers">    
  <form [formGroup]="form" class="private-influiencers__list">
    <label class="checked private-influencers__item"  *ngFor="let item of influencers; let i = index" formArrayName="influencers" >
      <input type="checkbox" class="checked__input" (change)="onChange()" [formControlName]="i">
      <span class="checked__square"></span>
      <div class="checked__text">
        <div class="private-influencers__name">{{item.first_name}} {{item.last_name}}</div>
        <div class="private-influencers__email">{{item.email}}</div>  
      </div>
    </label>
  
    <label class="checked private-influencers__all" (click)="onSelectAll($event)" >
      <input type="checkbox" class="checked__input" [checked]="allSelected" >
      <span class="checked__square"></span>
      <div class="checked__text">
       Select all 
      </div>
    </label>
  </form> 
</div>
