<div class="modal-block modal-brand-login" >
  <div class="modal__cont">
    <h3>{{'campaignOverview.post.dialog.declineTitle' | translate}}</h3>
    <div class="select-box">

      <div class="select" style='margin-top: 20px'>
        <label>
          <select name="declineReasons" [(ngModel)]='declineReasonsModel' (change)='reasonChange($event)'>
            <option value='default'>----</option>
            <option *ngFor="let reason of declineReasons">{{reason}}</option>
          </select>
        </label>
      </div>

      <label>
        <textarea #feedback class='feedback-change-text' [(ngModel)]='declineReason'
                  [placeholder]='"campaignOverview.post.dialog.declinePlaceholder" | translate'>
        </textarea>
      </label>


      <button type="button" class="btn big primary-btn-filled" [disabled]='!declineReason' [dialogClose]="declineReason">
        <span class="btn__text">{{'campaignOverview.post.decline' | translate}}</span>
      </button>

    </div>
  </div>
</div>
