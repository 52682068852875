export class CampaignAnalyticsModel {
  numberCreators: number;
  numberContents: number;
  trueReach: number;
  impression: number;
  totalEngagement: number;
  shareSaved: number;
  numberComments: number;
  numberLikes: number;
  postsAccepted: number;
  postsReceived: number;
  potentialReachApproved: number;
  potentialReachDeclined: number;
  potentialReachPublished: number;
  potentialReachUpdated: number;
  potentialReachPending: number;
  potentialReachTotal: number;
  storiesImpressions: number;
  storiesReach: number;
  storiesReplies: number;
}

export class CampaignAnalyticsM {
  numberCreators: number;
  numberContents: number;
  trueReach: number;
  totalEngagement: number;
  totalImpressions: number;
  totalSaved: number;
  totalShares: number;
  totalViews: number;
  potentialReachTotal: number;
  numberLikes: number;
  numberComments: number;
  totalContent: any[];
  breakdownContent: any;
  potentialReachApproved: number;
  potentialReachDeclined: number;
  potentialReachPublished: number;
  potentialReachUpdated: number;
  potentialReachPending: number;
}
