import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewStepComponent } from '@app/campaign/review-step/review-step.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ReviewStepComponent],
  imports: [CommonModule, CampaignWizardModule, TranslateModule],
  exports: [ReviewStepComponent],
})
export class ReviewStepModule {}
