import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CampaignAdapter } from '@app/shared/adapters/campaign.adapter';
import { CampaignModel, UserModel } from '@app/shared/model';
import { CampaignAnalyticsM, CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { CampaignAnalyticsAdapter } from '@app/shared/adapters/campaign-analytics.adapter';
import { CampaignDto } from '@app/shared/dto';
import { CommonService } from '@app/services/common.service';
import * as moment from 'moment';
import { CONFIG } from '@config/configuration.ts';
import { FeatureFlagsService } from '@app/services/feature-flag.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { UserService } from '@app/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private featureFlagService: FeatureFlagsService,
    private userService: UserService,
    private store: Store<AppState>
  ) {}

  getCampaigns(user: UserModel): Observable<CampaignModel[]> {
    let params = new HttpParams().set('platform_names', CONFIG.platformName);

    if (user?.isBrandOwner) {
      params = params.append('brand_owner', user?.email);
    }

    return this.http
      .get<any>(CONFIG.baseUrl + 'campaigns', { params })
      .pipe(
        map((response) => {
          const campaignsFromApi = response.campaign;
          const campaigns = [];

          for (const campaign of campaignsFromApi) {
            campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
            campaigns.push(CampaignAdapter.createCampaign(campaign));
          }
          return campaigns;
        })
      );
  }

  getCampaignFromApi(id: number): Observable<CampaignModel> {
    return this.http.get<any>(CONFIG.baseUrl + `campaigns/${id}`).pipe(
      map((response) => {
        const { campaign } = response;
        const { platformName } = CONFIG;
        const userEmail = this.getUserEmail();
        campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
        return CampaignAdapter.createCampaign(campaign);
      })
    );
  }

  private getUserEmail(): string {
    const user = this.userService.user;
    return user.email;
  }

  getCampaignAnalytics(id: number): Observable<CampaignAnalyticsM> {
    const params = new HttpParams().append('id', id + '');
    return this.http
      .get<any>(CONFIG.baseUrl + `analytics`, { params })
      .pipe(
        map((response) => {
          const { campaignAnalytics } = response;
          return CampaignAnalyticsAdapter.createCampaignAnalytics(campaignAnalytics);
        })
      );
  }

  getCampaignWalletTypes(): Observable<any> {
    const params = new HttpParams().append('platform_name', CONFIG.platformName);
    return this.http.get<any>(CONFIG.baseUrl + `campaign_wallet_types`, { params });
  }

  addCampaignMoodImage(campaignId: any, image: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    const body = new URLSearchParams();
    body.set('campaign_id', campaignId);
    body.set('mood_image', image);
    return this.http.post<any>(CONFIG.baseUrl + `addcampaignmoodimage`, body.toString(), options);
  }

  deleteCampaignMoodImage(campaignId: number): Observable<any> {
    if (!campaignId) {
      return of(true);
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        campaign_id: campaignId,
      },
    };

    return this.http.delete<any>(CONFIG.baseUrl + `deletecampaignmoodimage`, options);
  }

  submitCampaignDraft(campaign: Partial<CampaignModel>, userEmail: string): Observable<any> {
    const serializedCampaign = this.campaignSerializer(campaign, userEmail);
    let url = 'campaigns';
    let requestType = 'put';
    console.log('submit campaign ');
    if (!campaign.id) {
      requestType = 'post';
    } else {
      url = `campaigns/${campaign.id}`;
    }
    return this.http[requestType]<any>(CONFIG.baseUrl + url, { campaign: serializedCampaign }).pipe(
      map((response: any) => {
        const newCampaign = response.campaign;
        // this.createBrandsOwner(newCampaign.id, campaign.brandEmail).subscribe((res) => {
        //   return CampaignAdapter.createCampaign(newCampaign);
        // });
        return CampaignAdapter.createCampaign(newCampaign);
      })
    );
  }

  createBrandsOwner(campaign: number, email: string ): Observable<any> {
    const body = {
      brandOwners: { campaign, email }
    };
    return this.http.post(CONFIG.baseUrl + 'brandowners', body);
  }

  private campaignSerializer(campaign: Partial<CampaignModel>, userEmail: string): CampaignDto {
    const serializedCampaign = {} as CampaignDto;

    serializedCampaign.ageMax = campaign.ageMax;
    serializedCampaign.ageMin = campaign.ageMin;
    serializedCampaign.brand_comp_name = campaign.brandCompanyName;
    serializedCampaign.call_action = campaign.callAction;
    serializedCampaign.campaign_link = campaign.campaignLink;
    serializedCampaign.content_love = campaign.contentLove;
    serializedCampaign.dont_dos = campaign.dontDos;
    serializedCampaign.gender = campaign.gender;
    serializedCampaign.interests = campaign.interests;
    serializedCampaign.linkedin_interests = campaign.linkedinInterests;
    serializedCampaign.language = campaign.language;
    serializedCampaign.info_demographic = campaign.infoDemographic;
    serializedCampaign.open_until_date = moment(campaign.openUntilDate).format('YYYY-MM-DDThh:mm:ss');
    serializedCampaign.platform_fb = campaign.socialPlatforms.platformFb;
    serializedCampaign.platform_reels = campaign.socialPlatforms.platformReels;
    serializedCampaign.platform_insta = campaign.socialPlatforms.platformInsta;
    serializedCampaign.platform_insta_story = campaign.socialPlatforms.platformInstaStory;
    serializedCampaign.platform_tiktok = campaign.socialPlatforms.platformTiktok;
    serializedCampaign.platform_linkedin = campaign.socialPlatforms.platformLinkedin;
    serializedCampaign.pre_tags = this.setPreTags(campaign.preTags);
    serializedCampaign.pro_comp_name = campaign.campaignName;
    serializedCampaign.pro_desc = campaign.campaignDescription;
    serializedCampaign.pub_guides = campaign.pubGuides;
    serializedCampaign.brand_owner = campaign.brandEmail;
    serializedCampaign.reviewed = false;
    serializedCampaign.user = userEmail;

    if (campaign.publishBriefApp) {
      serializedCampaign.publish_brief_app = moment(campaign.publishBriefApp).format('YYYY-MM-DDThh:mm:ss');
    } else {
      serializedCampaign.publish_brief_app = new Date().toISOString();
    }

    if (campaign.heroImage) {
      serializedCampaign.hero_image = campaign.heroImage;
    }

    if (this.featureFlagService.isFeatureEnabled('campaignWallet') && campaign.campaignWalletTypeId) {
      serializedCampaign.campaign_wallet_type_id = campaign.campaignWalletTypeId;
    }

    if (campaign.campaignLogo) {
      serializedCampaign.campaign_logo = campaign.campaignLogo;
    }

    if (campaign.objective) {
      serializedCampaign.objective = campaign.objective;
    }

    if (campaign.campaignBudget) {
      serializedCampaign.campaign_budget = campaign.campaignBudget;
    }

    if (campaign.privateInfluencers) {
      serializedCampaign.private_influencers = campaign.privateInfluencers;
    }

    return serializedCampaign;
  }

  private setPreTags(tags: any): any {
    const preTags = {} as any;

    if (tags.facebook) {
      preTags.facebook = this.commonService.setPreTags(tags.facebook);
    }

    if (tags.instagram) {
      preTags.instagram = this.commonService.setPreTags(tags.instagram);
    }

    if (tags.tiktok) {
      preTags.tiktok = this.commonService.setPreTags(tags.tiktok);
    }

    if (tags.linkedin) {
      preTags.linkedin = this.commonService.setPreTags(tags.linkedin);
    }

    return preTags;
  }

  private hidePreTags(tags: any): any {
    const filteredTags = {} as any;

    if (tags.facebook) {
      filteredTags.facebook = this.commonService.hidePreTags(tags.facebook);
    }

    if (tags.instagram) {
      filteredTags.instagram = this.commonService.hidePreTags(tags.instagram);
    }

    if (tags.tiktok) {
      filteredTags.tiktok = this.commonService.hidePreTags(tags.tiktok);
    }

    if (tags.linkedin) {
      filteredTags.linkedin = this.commonService.hidePreTags(tags.linkedin);
    }

    return filteredTags;
  }
}
