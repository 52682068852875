import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostFeedbackComponent } from './post-feedback.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BrandPostDeclineModule } from '@app/dialogs/brand-post-decline/brand-post-decline.module';

@NgModule({
  declarations: [PostFeedbackComponent],
  imports: [CommonModule, MatTooltipModule, TranslateModule, BrandPostDeclineModule],
  exports: [PostFeedbackComponent],
})
export class PostFeedbackModule {}
