import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostOverviewDialogComponent } from './post-overview-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PostReviewChangeModule } from '@app/dialogs/post-review-change/post-review-change.module';
import { PostDeclineChangeModule } from '@app/dialogs/post-decline-change/post-decline-change.module';
import { PostEditingDialogModule } from '@app/dialogs/post-editing-dialog/post-editing-dialog.module';
import { DialogRef } from '@ngneat/dialog';
import { RouterModule } from '@angular/router';

// @ts-ignore
@NgModule({
  declarations: [PostOverviewDialogComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    PostReviewChangeModule,
    PostDeclineChangeModule,
    PostEditingDialogModule,
    RouterModule,
  ],
  exports: [PostOverviewDialogComponent], // @ts-ignore
  providers: [DialogRef],
})
export class PostOverviewDialogModule {}
