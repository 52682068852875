<section class="typical-page">
  <div class="wrapper">
    <h1 class="big">404</h1>

    <div class="typical-page__block">
      <div class="typical-page__group">
        <span class="typical-page__title">THE PAGE NOT FOUND!</span>
        <span class="typical-page__subtitle">Back to the home page</span>
      </div>

      <a routerLink='/' class="btn big primary-btn-filled">
        <span class="btn__text">home page</span>
      </a>

      <img src="assets/img/video-box_bg.jpg" alt="" class="typical-page__bg">
    </div>
  </div>
</section>
