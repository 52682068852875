import { createAction, props } from '@ngrx/store';
import { CampaignModel, PostModel, PostStatusTypes } from '@app/shared/model';
import { CampaignAnalyticsM, CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { BrandClientsDto } from '@app/shared/dto/brand-clients.dto';

export enum CampaignOverviewActionTypes {
  GET_SINGLE_CAMPAIGN = '[Campaign overview] Get Single Campaign',
  GET_SINGLE_CAMPAIGN_FROM_API = '[Campaign overview] Get Single Campaign From API',
  GET_SINGLE_CAMPAIGN_SUCCESS = '[Campaign overview] Get Single Campaign Success',
  GET_SINGLE_CAMPAIGN_FAIL = '[Campaign overview] Get Single Campaign Fail',

  GET_SINGLE_CAMPAIGN_ANALYTICS = '[Campaign overview] Get Single Campaign Analytics',
  GET_SINGLE_CAMPAIGN_ANALYTICS_SUCCESS = '[Campaign overview] Get Single Campaign Analytics Success',

  GET_SINGLE_CAMPAIGN_POSTS = '[Campaign overview] Get Single Campaign Posts',
  GET_SINGLE_CAMPAIGN_POSTS_SUCCESS = '[Campaign overview] Get Single Campaign Posts Success',
  GET_SINGLE_CAMPAIGN_POSTS_FAIL = '[Campaign overview] Get Single Campaign Posts Fail',

  DELETE_SINGLE_CAMPAIGN = '[Campaign overview] Single Campaign Reset to initial',

  APPROVE_CAMPAIGN_POST = '[Campaign overview] Approve campaign post',
  DECLINE_CAMPAIGN_POST = '[Campaign overview] Decline campaign post',
  CHANGE_CAMPAIGN_POST = '[Campaign overview] Change campaign post',

  CAMPAIGN_POST_SEEN = '[Campaign overview] Campaign post seen',
  CAMPAIGN_POST_SEEN_SUCCESS = '[Campaign overview] Campaign post seen success',
  CAMPAIGN_POST_STATUS_UPDATED = '[Campaign overview] Campaign post status updated',
  CAMPAIGN_POST_STATUS_FAIL = '[Campaign overview] Campaign post status fail',
  CAMPAIGN_POST_SELECTION = '[Campaign overview] Campaign post selected',
  CAMPAIGN_POSTS_SELECTION_RESET = '[Campaign overview] Campaign posts selection reset',
  CAMPAIGN_POSTS_CONTACT_BRAND = '[Campaign overview] Campaign posts contact brand',
  CAMPAIGN_POSTS_CONTACT_BRAND_SUCCESS = '[Campaign overview] Campaign posts contact brand success',
  CAMPAIGN_POSTS_CONTACT_BRAND_FAIL = '[Campaign overview] Campaign posts contact brand fail',
}

export const getSingleCampaign = createAction(CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN, (id: number) => ({
  id,
}));

export const getSingleCampaignAnalytics = createAction(
  CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN_ANALYTICS,
  (id: number) => ({
    id,
  })
);

export const getSingleCampaignAnalyticsSuccess = createAction(
  CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN_ANALYTICS_SUCCESS,
  props<{ analytics: CampaignAnalyticsM }>()
);

export const getSingleCampaignSuccess = createAction(
  CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN_SUCCESS,
  props<{ campaign: CampaignModel }>()
);

export const getSingleCampaignPosts = createAction(
  CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN_POSTS,
  (id: number) => ({
    id,
  })
);

export const getSingleCampaignPostsSuccess = createAction(
  CampaignOverviewActionTypes.GET_SINGLE_CAMPAIGN_POSTS_SUCCESS,
  props<{ posts: PostModel[]; isLoaded: boolean }>()
);

export const deleteSingleCampaign = createAction(CampaignOverviewActionTypes.DELETE_SINGLE_CAMPAIGN);

export const approveCampaignPost = createAction(
  CampaignOverviewActionTypes.APPROVE_CAMPAIGN_POST,
  props<{ postId: number; status: PostStatusTypes }>()
);

export const changeCampaignPost = createAction(
  CampaignOverviewActionTypes.CHANGE_CAMPAIGN_POST,
  props<{ postId: number; status: PostStatusTypes; feedback: string }>()
);

export const makePostSeen = createAction(CampaignOverviewActionTypes.CAMPAIGN_POST_SEEN, props<{ postId: number }>());
export const makePostSeenSuccess = createAction(
  CampaignOverviewActionTypes.CAMPAIGN_POST_SEEN_SUCCESS,
  props<{ postId: number }>()
);

export const declineCampaignPost = createAction(
  CampaignOverviewActionTypes.DECLINE_CAMPAIGN_POST,
  props<{ postId: number; status: PostStatusTypes; feedback: string }>()
);

export const postStatusUpdatedSuccess = createAction(
  CampaignOverviewActionTypes.CAMPAIGN_POST_STATUS_UPDATED,
  props<{ postId: number; status: PostStatusTypes; feedback?: string }>()
);
export const postStatusUpdateFail = createAction(CampaignOverviewActionTypes.CAMPAIGN_POST_STATUS_FAIL);

export const postSelection = createAction(
  CampaignOverviewActionTypes.CAMPAIGN_POST_SELECTION,
  props<{ postId: number; selected: boolean }>()
);

export const resetPostsSelection = createAction(CampaignOverviewActionTypes.CAMPAIGN_POSTS_SELECTION_RESET);

export const contactBrand = createAction(
  CampaignOverviewActionTypes.CAMPAIGN_POSTS_CONTACT_BRAND,
  props<{ brandClients: BrandClientsDto }>()
);

export const contactBrandSuccess = createAction(CampaignOverviewActionTypes.CAMPAIGN_POSTS_CONTACT_BRAND_SUCCESS);
export const contactBrandFail = createAction(
  CampaignOverviewActionTypes.CAMPAIGN_POSTS_CONTACT_BRAND_FAIL,
  props<{ error: Error }>()
);
