import * as userActions from './user.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { UserModel } from '@app/shared/model';

export interface UserState {
  isLoaded: boolean;
  profile: UserModel | null;
}

export const initialState: UserState = {
  isLoaded: false,
  profile: null,
};

const userReducerInternal = createReducer(
  initialState,

  on(userActions.getUserSuccess, userActions.createNewUserSuccess, (state, { profile, isLoaded }) => {
    return {
      ...state,
      profile,
      isLoaded,
    };
  })
);

export function userReducer(state: UserState | null, action: Action): UserState {
  return userReducerInternal(state, action);
}
