import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitComponent } from './submit.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [SubmitComponent],
  exports: [SubmitComponent],
  imports: [CommonModule, CampaignWizardModule, ReactiveFormsModule, TranslateModule, MatDatepickerModule, FormsModule],
})
export class SubmitModule {}
