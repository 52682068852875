import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@app/services/common.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@ngneat/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: string;

  forgetForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email]],
    },
    {
      updateOn: 'change',
    }
  );

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    public ref: DialogRef
  ) {}

  ngOnInit(): void {}

  resetPassword(): void {
    if (!this.forgetForm.valid) {
      return;
    }

    const email = this.forgetForm.get('email').value;
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');
    const successMsg = this.translate.instant('resetPassword.successMsg');

    this.commonService
      .resetPassword(email)
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe((response) => {
        if (!response.success) {
          this.forgetForm.controls.email.setErrors({ errorFromApi: response.result });
        } else {
          this.toastr.success(successMsg);
          this.forgetForm.reset();
          this.ref.close();
          this.router.navigate(['/']);
        }
      });
  }
}
