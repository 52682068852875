<section class="overview mb-100 mt-100">
  <div class="overview-header">
    <div class="wrapper">
      <div class="overview-header__left">
        <img [src]="campaign?.heroImageUrl" [hidden]='!heroImageLoaded' (load)='heroImageLoaded = true' alt="" class="overview-header__img">
        <span class="overview-header__open-until">{{'campaignOverview.openUntil' | translate}} <b>{{campaign?.openUntilDate | date: 'longDate' }}</b></span>

        <span *ngIf='isBrandAccountFeature' class="overview-header__open-until" style='margin: 0; font-size: 14px'>{{'campaignOverview.totalPostsCosts' | translate}} <b>{{currencySymbol}} {{campaign?.totalPostsCosts || 0}}</b></span>
      </div>
      <div class="overview-header__right">
        <div class="overview-header__row">
          <h2>{{campaign?.campaignName}}</h2>
          <button *ngIf='isAgency && !disableButtons && !iolDisableContactBrand' type="button" class="btn small primary-btn-radius" (click)='contactBrand()' [ngClass]='{"btn__hidden": !numberSelectedPosts()?.length}' >
            <span class="btn__text"> {{'campaignOverview.contact' | translate}} ({{numberSelectedPosts()?.length}})</span>
          </button>
        </div>
        <div class="overview-header__row" *ngIf='!isBrandAccountFeature && !isBrandOwner'>
          <div class="overview-header__colum left">
            <ng-container *ngIf="campaignWallet && !disableButtons">
              <span class="overview-header__text big"><b>{{currencySymbol}}</b>{{campaign?.campaignSpent}} {{'campaignOverview.currentSpend' | translate}}</span>
              <span class="overview-header__line"></span>
              <div style='position: relative'>
                <span class="overview-header__text big"><b>{{currencySymbol}}</b>{{campaign?.campaignWallet}} Budget</span>
                <div class="information information__campaign-wallet" matTooltip="{{'newCampaign.campaignBudgetIva' | translate }}">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
                    <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
                  </svg>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!campaignWallet">
              <span class="overview-header__text big">{{'campaignOverview.currentSpend' | translate}}</span>
              <span class="overview-header__line"></span>
              <span class="overview-header__number large"><b>{{currencySymbol}}</b> {{campaign?.campaignSpent}}</span>
            </ng-container>
          </div>
          <div class="overview-header__colum">
            <div class="overview-header__gorup">
              <span class="overview-header__line-one"></span>
              <span class="overview-header__number large">{{campaign?.postSubmissions}} </span>
              <span class="overview-header__text small">{{'campaignOverview.posts' | translate}}</span>
            </div>
            <div class="overview-header__gorup">
              <span class="overview-header__line-one"></span>
              <span class="overview-header__number large">{{campaign?.postsAccepted}}</span>
              <span class="overview-header__text small">{{'campaignOverview.accepted' | translate}}</span>
            </div>
          </div>
          <div class="overview-header__colum">
            <div class="overview-header__gorup">
              <span class="overview-header__line-two"></span>
              <span class="overview-header__number large">{{analytics?.numberLikes}}</span>
              <span class="overview-header__text small">{{'campaignOverview.likes' | translate}}</span>
            </div>
            <div class="overview-header__gorup">
              <span class="overview-header__line-two"></span>
              <span class="overview-header__number large">{{analytics?.numberComments}}</span>
              <span class="overview-header__text small">{{'campaignOverview.comments' | translate}}</span>
            </div>
          </div>
          <div class="overview-header__colum right">
            <span class="overview-header__text big">{{'campaignOverview.potentialReach' | translate}}</span>
            <span class="overview-header__line"></span>
            <span class="overview-header__number big">{{potentialReach}}</span>
          </div>
        </div>

        <div class="overview-header__row isBrand" *ngIf='isBrandOwner'>

          <div class='status_row'>
            <div class='post-block__seen icon'></div>
            <div>{{'campaignOverview.brand.statusNew' | translate}}</div>
          </div>

          <div class='status_row'>
            <div class='post-block__seen icon modified'></div>
            <div>{{'campaignOverview.brand.statusModified' | translate}}</div>
          </div>

          <div class='status_row'>
            <div class='post-block__complited icon'></div>
            <div>{{'campaignOverview.brand.statusUpdated' | translate}}</div>
          </div>


        </div>
<!-- only brands account template start -->
        <div class="overview-header__row" *ngIf='isBrandAccountFeature'>
          <div class="overview-header__colum left">

            <ng-container *ngIf="!campaignWallet">
              <span class="overview-header__text medium">{{'campaignOverview.currentSpend' | translate}}</span>
              <span class="overview-header__number medium"><b>{{currencySymbol}}</b>{{campaign?.campaignSpent}}</span>
              <span class="overview-header__line"></span>

              <span class="overview-header__text medium">{{'newCampaign.campaignBudget' | translate}}</span>
              <span class="overview-header__number medium"><b>{{currencySymbol}}</b>{{campaign?.campaignBudget || 0}}</span>
            </ng-container>
          </div>
          <div class="overview-header__colum">
            <div class="overview-header__gorup">
              <span class="overview-header__line-one"></span>
              <span class="overview-header__number large">{{campaign?.postSubmissions}} </span>
              <span class="overview-header__text small">{{'campaignOverview.posts' | translate}}</span>
            </div>
            <div class="overview-header__gorup">
              <span class="overview-header__line-one"></span>
              <span class="overview-header__number large">{{campaign?.postsAccepted}}</span>
              <span class="overview-header__text small">{{'campaignOverview.accepted' | translate}}</span>
            </div>
          </div>
          <div class="overview-header__colum">
            <div class="overview-header__gorup">
              <span class="overview-header__line-two"></span>
              <span class="overview-header__number large">{{analytics?.numberLikes}}</span>
              <span class="overview-header__text small">{{'campaignOverview.likes' | translate}}</span>
            </div>
            <div class="overview-header__gorup">
              <span class="overview-header__line-two"></span>
              <span class="overview-header__number large">{{analytics?.numberComments}}</span>
              <span class="overview-header__text small">{{'campaignOverview.comments' | translate}}</span>
            </div>
          </div>
          <div class="overview-header__colum right">
            <span class="overview-header__text medium">{{'campaignOverview.potentialReach' | translate}}</span>
            <span class="overview-header__number medium">{{posts?.length ? potentialReach : 0}}</span>
            <span class="overview-header__line"></span>
            <div *ngIf='numberSelectedPosts().length'>
              <span class="overview-header__text medium">{{'campaignOverview.costsSelected' | translate }}</span><br/>
              <span class="overview-header__number medium"><b>{{currencySymbol}}</b> {{amountSelectedPosts()}}</span>
            </div>
          </div>
        </div>
<!-- only brands account template end -->

      </div>
    </div>


    <img src="assets/img/overview/overview__bg.jpg" alt="" class="overview-header__bg">
  </div>

  <!-- note that on mobile there is a copy of the buttons from the 'overview-filter__btn-group' block -->
  <div class="overview-filter-btn-mobile">
    <button routerLink='analytics' type="button" class="btn primary-btn-filled">
      <span class="btn__text">{{'campaignOverview.analytics' | translate}}</span>
    </button>

    <button routerLink='edit' type="button" class="btn campaign-secondary-btn">
      <span class="btn__text-small">{{'campaignOverview.edit' | translate}}</span>
    </button>

    <span class="overview-filter__line"></span>
  </div>

  <div class="overview-filter">
    <div class="wrapper">
      <div class="overview-filter__btn-group">
        <button routerLink='analytics' type="button" class="btn primary-btn-filled">
          <span class="btn__text">{{'campaignOverview.analytics' | translate}}</span>
        </button>

        <button routerLink='edit' *ngIf='!isBrandOwner' type="button" class="btn campaign-secondary-btn">
          <span class="btn__text-small">{{'campaignOverview.edit' | translate}}</span>
        </button>
      </div>

      <div class="overview-filter__select-group">
        <div class="select-box">
          <span class="form__title">{{'campaignOverview.showPosts' | translate}}</span>
          <div class="select">
            <label>
              <select [(ngModel)]='postStatusModel' name="postStatusFilter" (change)='postsStatusFilterChange($event)'>
                <option value='all'>{{ statuses.all ? ('campaignOverview.statuses.all' | translate) +' ('+statuses.all+')' : ('campaignOverview.statuses.all' | translate)}}</option>
                <option [disabled]='statuses.approved === 0' value='approved'>{{'campaignOverview.statuses.approved' | translate}} ({{statuses.approved}})</option>
                <option [disabled]='statuses.declined === 0' value='declined'>{{'campaignOverview.statuses.declined' | translate}} ({{statuses.declined}})</option>
                <option [disabled]='statuses.pending === 0' value='pending'>{{'campaignOverview.statuses.pending' | translate}} ({{statuses.pending}})</option>
                <option *ngIf='isBrandOwner' [disabled]='statuses.pendingBrandRevision === 0' value='pendingBrandRevision'>{{'campaignOverview.statuses.pendingBrandRevision' | translate}} ({{statuses.pendingBrandRevision}})</option>
                <option [disabled]='statuses.updated === 0' value='updated'>{{'campaignOverview.statuses.updated' | translate}} ({{statuses.updated}})</option>
                <option [disabled]='statuses.published === 0' value='published'>{{'campaignOverview.statuses.published' | translate}} ({{statuses.published}})</option>
              </select>
            </label>
          </div>
        </div>

        <div class="select-box">
          <span class="form__title">{{'campaignOverview.sortBy' | translate}}</span>
          <div class="select">
            <label>
              <select [(ngModel)]='sortByModel' name="sortPostsBy" (change)='sortPostsByChange()'>
                <option value='updatedDatetime'>{{'campaignOverview.sort.date' | translate}}</option>
                <option value='postPriceTechFee'>{{'campaignOverview.sort.price' | translate}}</option>
                <option value='followers'>{{'campaignOverview.sort.followers' | translate}}</option>
                <option value='engagementRate'>{{'campaignOverview.sort.rate' | translate}}</option>
              </select>
            </label>
          </div>
        </div>

        <button type="button" class="filter-arrow" (click)='posts.reverse()'>
          <svg class="filter-arrow__svg" width="14" height="19" viewBox="0 0 14 19" fill="currentColor">
            <path d="M5 6.4357L5 -3.61173e-07L3 -4.41433e-07L3 6.4357L6.47257e-07 6.4357L4 10.0988L8 6.4357L5 6.4357ZM6 11.9258L9 11.9258L9 18.3615L11 18.3615L11 11.9258L14 11.9258L10 8.26267L6 11.9258Z"/>
          </svg>
        </button>
      </div>

      <div class="filter-soc-net">
        <span class="form__title">{{'campaignOverview.filterBy' | translate}}</span>

        <div class="filter-soc-net__box">
          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.instagram'
               (click)='filterPostsBySocialNetwork("instagram")'>
            <img src="assets/img/icons/in-color.svg" alt="" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.instagramstory'
               (click)='filterPostsBySocialNetwork("instagramstory")'>
            <img src="assets/img/icons/in-story-color.svg" alt="" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.reels'
               (click)='filterPostsBySocialNetwork("reels")'>
            <img style='height: 40px' src="assets/img/icons/reels_icon.png" alt="" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.facebook'
               (click)='filterPostsBySocialNetwork("facebook")'>
            <img src="assets/img/icons/fb-color.svg" alt="" class="filter-soc-net__img">
          </div>

          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.tiktok'
               (click)='filterPostsBySocialNetwork("tiktok")'>
            <img src="assets/img/icons/tiktok-color.png" alt="" class="filter-soc-net__img">
          </div>

          <div class="filter-soc-net__item" [class.active]='socialNetworkFilter.linkedin'
               (click)='filterPostsBySocialNetwork("linkedin")'>
            <img src="assets/img/icons/linkedin.png" alt="" class="filter-soc-net__img">
          </div>
        </div>
      </div>

      <span class="overview-filter__line"></span>
    </div>
  </div>

  <div *ngIf="isPostsLoading; then spinner else postsView"></div>

  <ng-template #spinner>
    <div class='progress-spinner'>
      <mat-spinner diameter='70'></mat-spinner>
    </div>
  </ng-template>

  <ng-template #postsView>
    <div class="overview-main">
      <div class='no-posts__text' *ngIf='noPostsInCampaignMessage'>
        <h3> {{'campaignOverview.noPostsMessage' | translate}}</h3>
      </div>
      <div class="wrapper">
        <app-post [hideSelect]='iolDisableContactBrand' (click)='postOverview(post)' (selectedPost)='selectedPost($event)' [post]='post' [showPrices]='campaign.showPrices'  *ngFor='let post of posts trackBy: trackByIndex'></app-post>
      </div>
    </div>
  </ng-template>
</section>
