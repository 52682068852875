import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaypalDialogComponent } from './paypal-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [PaypalDialogComponent],
    imports: [CommonModule, BrowserModule, ReactiveFormsModule, TranslateModule],
})
export class PaypalDialogModule {}
