import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, State, Store } from '@ngrx/store';

import { getCampaigns, selectCampaigns, selectCampaignsByInterests, selectFilteredCampaigns } from '@store/campaigns';
import { Subject, Subscription } from 'rxjs';
import { CampaignModel } from '@app/shared/model';
import { AppState } from '@store/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import * as campaignDraftActions from '@store/campaign-draft';
import { FormBuilder, FormControl } from '@angular/forms';
import { CONFIG } from '@config/configuration';
import { selectUserInfo } from '@app/store';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit, OnDestroy {
  campaignSubscription: Subscription;
  activeCampaigns: CampaignModel[];
  completedCampaigns: CampaignModel[];
  private destroyed$ = new Subject<boolean>();
  private interests: string[] = [];
  private initialCampaigns = [];
  search: any;
  noFilteredCampaigns = false;
  interestsFilter = false;
  interestsArrays = CONFIG.platform.interests;
  searchForm = this.fb.group({
    search: [''],
  });

  constructor(
    private store: Store<AppState>,
    private state: State<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private actions: Actions,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.search = this.searchForm.controls.search;
    this.store.pipe(select(selectUserInfo)).subscribe((user) => {
      return this.store.dispatch(getCampaigns({ user }));
    });
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitSuccess), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.subscribeCampaigns();
      });
    this.subscribeCampaigns();

    this.search.valueChanges.subscribe((value: string) => {
      this.store.pipe(select(selectFilteredCampaigns(value)), takeUntil(this.destroyed$)).subscribe((campaigns) => {
        const filteredCampaigns = value ? campaigns : this.initialCampaigns;
        this.updateCampaigns(filteredCampaigns);

        this.noFilteredCampaigns = value && !this.activeCampaigns.length && !this.completedCampaigns.length;
      });
    });
  }

  private subscribeCampaigns(): void {
    this.store.pipe(select(selectCampaigns), takeUntil(this.destroyed$)).subscribe((campaigns) => {
      this.initialCampaigns = campaigns.slice();
      this.updateCampaigns(campaigns);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeInterests(event, interest: string): void {
    const checked = event.target.checked;
    if (checked) {
      this.searchForm.reset();
      this.interests.push(interest);
    } else {
      this.interests.splice(this.interests.indexOf(interest), 1);
    }

    this.store
      .pipe(select(selectCampaignsByInterests(this.interests)), takeUntil(this.destroyed$))
      .subscribe((campaigns) => {
        if (!campaigns.length) {
          this.subscribeCampaigns();
          return;
        }
        this.updateCampaigns(campaigns);
      });
  }

  private updateCampaigns(campaigns: CampaignModel[]): void {
    this.activeCampaigns = this.getActiveCampaigns(campaigns);
    this.completedCampaigns = this.getCompletedCampaigns(campaigns);
  }

  getActiveCampaigns(campaigns: CampaignModel[]): CampaignModel[] {
    return (campaigns && campaigns.filter((campaign) => campaign.isLive === true || campaign.isLive === false && campaign.isPending)) || [];
  }

  getCompletedCampaigns(campaigns: CampaignModel[]): CampaignModel[] {
    return (campaigns && campaigns.filter((campaign) => campaign.isLive === false && !campaign.isPending)) || [];
  }

  openCampaign(campaign: CampaignModel): void {
    if (!campaign.reviewed) {
      return;
    }
    this.router.navigate([campaign.id], { relativeTo: this.activatedRoute });
  }
}
