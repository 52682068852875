/* tslint:disable */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromUserActions from './user.actions';
import * as fromBrandActions from '../brand/brand.actions';
import { UserService } from '@app/services/user.service';
import { of } from 'rxjs';
import { UserAdapter } from '@app/shared/adapters/user.adapter';
import { UserModel } from '@app/shared/model';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService) {}

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUser),
      mergeMap(() =>
        this.userService
          .getUser()
          .pipe(map((user) => fromUserActions.getUserSuccess({ profile: user, isLoaded: true })))
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.createNewUser),
      switchMap(({ user }) =>
        this.userService.createNewUser(user).pipe(
          map((user) => fromUserActions.createNewUserSuccess({ profile: user, isLoaded: true })),
          catchError(({ error }) => of(fromUserActions.createNewUserFail({ error })))
        )
      )
    )
  );

  updateUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.updateUserInfo),
      switchMap(({ user }) =>
        this.userService.updateUserInfo(user).pipe(
          mergeMap(({ user }) => [
            fromUserActions.updateUserInfoSuccess(),
            fromUserActions.getUserSuccess({ profile: UserAdapter.createUser(user), isLoaded: true }),
          ]),
          catchError(({ error }) => of(fromUserActions.updateUserInfoFail()))
        )
      )
    )
  );

  getBrandAfterUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUserActions.getUserSuccess),
      map(() => fromBrandActions.getBrand())
    )
  );
}
