import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfluencersStepComponent } from '@app/campaign/influencers-step/influencers-step.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PrivateInfluencersComponent } from './private-influencers/private-influencers.component';
import { SelectInterestsComponent } from './select-interests/select-interests.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [InfluencersStepComponent, PrivateInfluencersComponent, SelectInterestsComponent],
  imports: [CommonModule, CampaignWizardModule, NgxSliderModule, TranslateModule, ReactiveFormsModule,
    FormsModule, MatTabsModule],
  exports: [InfluencersStepComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InfluencersStepModule {}
