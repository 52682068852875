import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { HelperService } from '@app/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogRef } from '@ngneat/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@app/services/user.service';
import { selectBrand, selectUserInfo } from '@app/store';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { BrandModel, UserModel } from '@app/shared/model';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();
  private brandId: number;

  inviteUserForm = this.fb.group(
    {
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    }
  );
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private helperService: HelperService,
    private translate: TranslateService,
    private router: Router,
    public ref: DialogRef,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    private userService: UserService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
      if (brand) {
        this.brandId = brand.id;
      }
    });
  }

  inviteUser(): void {
    if (!this.inviteUserForm.valid) {
      return;
    }

    const firstName = this.inviteUserForm.get('name').value;
    const password = Math.random().toString(36).substring(6);
    const lastName = this.inviteUserForm.get('surname').value;
    const email = this.inviteUserForm.get('email').value;
    const isAdditionalBrandUser = this.brandId;
    const isBrand = false;
    const successMsg = this.translate.instant('inviteUser.successMsg');

    const user = {
      firstName,
      lastName,
      username: email,
      isAdditionalBrandUser,
      email,
      password,
      isBrand,
    } as Partial<UserModel>;

    this.userService
      .inviteUser(user)
      .pipe(
        catchError(({error}) => {
          this.toastr.error(error.user.email[0]);
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.dialogRef.close();
      });
  }

}
