import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { select, Store } from '@ngrx/store';
import { getUser, selectIsLoaded } from '@store/index';
import { Observable } from 'rxjs';
import { AppState } from '@store/app.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  isLoaded$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private store: Store<AppState>
  ) {
    // this.userProfile$ = this.store.select(selectUser);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }
  checkLogin(url: string): boolean {
    if (this.tokenService.getRefreshToken()) {
      // this.getInitialData();
      return true;
    }

    this.authService.redirectUrl = url;

    this.router.navigate(['/login']).then((_) => false);
  }
  getInitialData(): void {
    this.isLoaded$ = this.store.pipe(select(selectIsLoaded));
    this.isLoaded$.subscribe((loaded) => {
      if (!loaded) {
        this.store.dispatch(getUser());
      }
    });
  }
}
