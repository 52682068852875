import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { deleteCampaignDraft, deleteSingleCampaign, getSingleCampaign } from '@app/store';

import { AppState } from '@store/app.state';

@Component({
  selector: 'app-campaign-overview',
  templateUrl: './campaign-overview.component.html',
})
export class CampaignOverviewComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  id: number;

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.id = +params.id;
      this.store.dispatch(getSingleCampaign(this.id));
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.store.dispatch(deleteCampaignDraft());
    this.store.dispatch(deleteSingleCampaign());
  }
}
