import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
// @ts-ignore
import worldMap from '@highcharts/map-collection/custom/world.geo.json';

@Component({
  selector: 'app-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss'],
})
export class MapChartComponent implements OnInit {
  @Input() countries: [[string, number]];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  chartConstructor = 'mapChart';

  ngOnInit(): void {
    this.initializationChart(this.toLowerCaseArray(this.countries));
  }

  private toLowerCaseArray(arrayOfArrays: [[string, number]]): (any[] | [string, number])[] {
    if (!arrayOfArrays) {
      return;
    }
    const filteredArray = arrayOfArrays.filter((value) => value.length === 2);

    return filteredArray.map((array: [string, number]) => {
      if (array.length !== 2) {
        return [];
      }
      return array.map((arrayItem: string | number): string | number => {
        if (typeof arrayItem === 'string') {
          return arrayItem.toLowerCase();
        }
        return arrayItem;
      });
    });
  }

  private initializationChart(data): void {
    this.chartOptions = {
      chart: {
        map: worldMap,
        style: {
          fontFamily: "font-family: 'OpenSans', sans-serif",
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: 'spacingBox',
        },
      },

      legend: {
        enabled: false,
      },

      colorAxis: [
        {
          minColor: '#C5CCFF',
          maxColor: '#8057AE',
        },
      ],
      series: [
        {
          nullColor: '#EBEEFF',
          type: 'map',
          animation: true,
          name: '',
          borderColor: '#EBEEFF',
          states: {
            hover: {
              color: '#a4edba',
            },
          },
          tooltip: {
            valueSuffix: '',
          },
          data,
        },
      ],
    };
  }
}
