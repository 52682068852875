import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore.default(Highcharts);
HighchartsSolidGauge.default(Highcharts);

@Component({
  selector: 'app-gauge-chart',
  template: '<div id="container-chart"></div>',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit {
  @Input() audience!: number;

  constructor() {}

  ngOnInit(): void {
    this.initChart(this.buildGauge());
  }

  private initChart(gaugeOptions: any): void {
    Highcharts.chart(
      'container-chart',
      Highcharts.merge(gaugeOptions, {
        yAxis: {
          min: 0,
          max: 100,
          height: 300,

          minorTickInterval: 'auto',
          minorTickWidth: 1,
          minorTickLength: 20,
          minorTickPosition: 'inside',
          minorTickColor: '#47136B',

          tickPixelInterval: 80,
          tickWidth: 2,
          tickPosition: 'inside',
          tickLength: 40,
          tickColor: '#47136B',

          labels: {
            step: 2,
            distance: -60,
            rotation: 'auto',
            style: {
              fontSize: '22px',
              fontWeight: 'bold',
              color: '#47136B',
            },
          },
          title: {
            text: '',
          },
          plotBands: [
            {
              from: 60,
              to: 70,
              color: '#e3faf4',
              thickness: 40,
            },
            {
              from: 70,
              to: 80,
              color: '#c9f8ef',
              thickness: 40,
            },
            {
              from: 90,
              to: 80,
              color: '#a5f6e3',
              thickness: 40,
            },
            {
              from: 100,
              to: 90,
              color: '#49f5ca',
              thickness: 40,
            },
          ],
        },

        tooltip: {
          style: {
            fontSize: '16px',
          },
        },

        series: [
          {
            name: 'Quality',
            style: {
              fontSize: '50px',
            },
            dataLabels: {
              borderWidth: 0,
              style: {
                fontSize: 20,
                color: '#EB177C',
              },
            },
            data: [this.audience],
            tooltip: {
              valueSuffix: ' ',
            },
          },
        ],
      })
    );
  }

  private buildGauge(): any {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        style: {
          fontFamily: "font-family: 'OpenSans', sans-serif",
        },
      },

      title: {
        text: '',
        fontSize: '30px',
      },

      tooltip: {
        fontSize: '50px',
      },

      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: '#fff',
            borderWidth: 0,
            outerRadius: '109%',
          },
        ],
      },
    };
  }
}
