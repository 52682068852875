import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CampaignComponent } from '@app/campaign/campaign.component';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CampaignComponent> {
  canDeactivate(
    component: CampaignComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const isSubmitPage = state.url.includes('submit');

    if (!component || isSubmitPage) {
      return of(true);
    }
    console.log('state', state);
    return component.discardChanges();
  }
}
