import { createAction, props } from '@ngrx/store';
import { CampaignModel, UserModel } from '@app/shared/model';

export enum CampaignsActionTypes {
  GET_CAMPAIGNS = '[Campaigns] Get Campaigns',
  GET_CAMPAIGNS_SUCCESS = '[Campaigns] Get Campaigns Success',
  GET_CAMPAIGNS_FAIL = '[Campaigns] Get Campaigns Fail',
}

export const getCampaigns = createAction(
  CampaignsActionTypes.GET_CAMPAIGNS,
  props<{ user: UserModel}>()
  );
export const getCampaignsSuccess = createAction(
  CampaignsActionTypes.GET_CAMPAIGNS_SUCCESS,
  props<{ campaign: CampaignModel[] }>()
);
