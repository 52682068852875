import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'flytrendy.com', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom-left',
  palette: {
    popup: {
      background: '#47136B',
    },
    button: {
      background: '#41E2BA',
      text: '#fff',
    },
  },
  content: {
    message:
      'We and some selected partners use cookies or similar technologies as specified in the cookie policy.\n' +
      'You can consent to the use of these technologies by closing this information, continuing to browse this page, interacting with a link or button outside this information or continuing to navigate in any other way.',
    dismiss: 'Accetta',
    allow: 'Accept',
    deny: 'Decline',
    link: 'Learn more',
    href: 'https://flytrendy.com/cookie-policy',
    policy: 'Cookie Policy',
  },
  theme: 'classic',
  type: 'opt-out',
};
