<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
<div class="new-campaign-submit mt-50 mb-50">
  <div class="new-campaign-form__wrapper">
    <div class="new-campaign-submit__colum">
      <div class="form__box">
        <span class="form__title">{{'newCampaign.submit.publishBrief' | translate}}</span>

        <!-- to activate the button we use the 'active' class -->
        <div class="new-campaign-form__btn-group">
          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : publish.now}'>
            <span class="btn__text">{{'newCampaign.review.now' | translate}}</span>
          </button>

          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : publish.later}'>
            <span class="btn__text">{{'newCampaign.review.later' | translate}}</span>
          </button>
        </div>
      </div>

      <div class="form__box mt-30" *ngIf='publish.later'>
        <input [min]="todayDate" [(ngModel)]='publishBriefApp' (click)="pickerBrief.open()" [matDatepicker]="pickerBrief"  type="text" class="form__input" placeholder="DD/MM/YYYY">
<!--        <span class="form__error">error text</span>-->
        <mat-datepicker #pickerBrief></mat-datepicker>
      </div>
    </div>

    <div class="new-campaign-submit__colum">
      <div class="form__box">
        <span class="form__title">{{'newCampaign.submit.openUntil' | translate}}</span>
        <input [min]="todayDate" [(ngModel)]='openUntilDate' (click)="picker.open()" [matDatepicker]="picker" class="form__input" placeholder="DD/MM/YYYY">
        <mat-datepicker #picker></mat-datepicker>
<!--        <span class="form__error">error text</span>-->
      </div>

      <ng-container *featureFlag="'acceptTerms'">
      <div class="form__box mt-30">
        <label class="checked">
          <input (change)="agreePolicy($event)" type="checkbox" class="checked__input">
          <span class="checked__square"></span>
          <span class="checked__text">{{'general.acceptConditions' | translate}} <a routerLink='/privacy-policy' target="_blank"> Privacy Policy</a></span>
        </label>

        <label class="checked">
          <input (change)="agreeTerms($event)" type="checkbox" class="checked__input">
          <span class="checked__square"></span>
          <span class="checked__text">{{'general.acceptConditions' | translate}} <a routerLink='/terms-brand' target="_blank"> T&C's</a></span>
        </label>
      </div>
      </ng-container>
    </div>
  </div>
</div>
</app-campaign-wizard-step>
