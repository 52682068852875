import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostDeclineChangeComponent } from './post-decline-change.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@ngneat/dialog';

@NgModule({
  declarations: [PostDeclineChangeComponent],
  imports: [CommonModule, TranslateModule, FormsModule, DialogModule],
})
export class PostDeclineChangeModule {}
