import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitStepComponent } from '@app/campaign/submit-step/submit-step.component';
import { SubmitAuthorizedModule } from '@app/campaign/submit-step/submit-authorized/submit-authorized.module';
import { SubmitModule } from '@app/campaign/submit-step/submit/submit.module';

@NgModule({
  declarations: [SubmitStepComponent],
  imports: [CommonModule, SubmitAuthorizedModule, SubmitModule],
  exports: [SubmitStepComponent],
})
export class SubmitStepModule {}
