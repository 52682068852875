import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PostService } from '@app/services/post.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { DialogService } from '@ngneat/dialog';
import { QualityScoreComponent } from '@app/dialogs/quality-score/quality-score.component';
import { CONFIG } from '@config/configuration.ts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  postId: number;
  insights: any;
  audience: number;
  audienceGenderAge: { y: number; name: string }[];
  audienceCountry: { y: number; name: string }[];
  audienceCity: { y: number; name: string }[];
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);
  platformName = CONFIG.platform.name;

  chartColors = CONFIG.platform.insightsChartsColor || {
    genderChartColors: {
      colorStart: '#47136B',
      colorEnd: '#4566E1'
    },
    countryChartColors: {
      colorStart: '#B706F5',
      colorEnd: '#E241DC'
    },
    cityChartColors: {
      colorStart: '#47136B',
      colorEnd: '#4566E1'
    }
  };

  constructor(
    private route: ActivatedRoute,
    private postsService: PostService,
    private router: Router,
    private dialog: DialogService,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe((lang) => {
      this.buildData();
    });
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.postId = +params.id;
      const postsInsights = this.postsService.getInsights();
      if (!postsInsights) {
        this.router.navigate(['/campaigns']);
        return;
      }

      this.insights = postsInsights.insightsViewSet.result;
      this.audience = this.insights?.audience_quality;

      this.buildData();
    });
  }

  private buildData(): void {
    this.audienceGenderAge = this.adaptiveData(this.insights.audience_gender_age, 'gender');
    this.audienceCountry = this.adaptiveData(this.insights.audience_country, 'country');
    this.audienceCity = this.adaptiveData(this.insights.audience_city, 'city');
  }

  private replaceGenderString(genderString: string): string {
    if (genderString.includes('F')) {
      return genderString.replace('F.', 'Female ');
    }
    if (genderString.includes('M')) {
      return genderString.replace('M.', 'Male ');
    }
    if (genderString.includes('U')) {
      const translatedString = this.translate.instant('insights.notDefined');
      return genderString.replace('U.', translatedString);
    }
    return genderString;
  }

  private adaptiveData(data: [[string, number]], type: string): { y: number; name: string }[] {
    if (!data) {
      return;
    }
    const filteredData = data.filter((value) => value.length === 2);

    return filteredData.map((array: [string, number]) => {
      return {
        name: type === 'gender' ? this.replaceGenderString(array[0]) : array[0],
        y: array[1],
      };
    });
  }

  aboutQualityScore(): void {
    const dialog = this.dialog.open(QualityScoreComponent, {});
  }
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.postsService.setInsights(null);
  }
}
