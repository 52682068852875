<div class='tile' [ngClass]='isTabs ? "two-tabs": ""' *ngIf='!linkedinInterests'>
  <div (click)='toggleInterests("Sports, Fitness & Wellbeing")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Sports, Fitness & Wellbeing")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.sportsFitnessWellbeing' | translate}}</span>
    <svg class='interests__svg' width='70' height='60' viewBox='0 0 70 60'>
      <path
        d='M45.2083 11.6667C48.4167 11.6667 51.0417 9.04167 51.0417 5.83333C51.0417 2.625 48.4167 0 45.2083 0C42 0 39.375 2.625 39.375 5.83333C39.375 9.04167 42 11.6667 45.2083 11.6667ZM14.5833 30.625C6.41667 30.625 0 37.0417 0 45.2083C0 53.375 6.41667 59.7917 14.5833 59.7917C22.75 59.7917 29.1667 53.375 29.1667 45.2083C29.1667 37.0417 22.75 30.625 14.5833 30.625ZM14.5833 55.4167C9.04167 55.4167 4.375 50.75 4.375 45.2083C4.375 39.6667 9.04167 35 14.5833 35C20.125 35 24.7917 39.6667 24.7917 45.2083C24.7917 50.75 20.125 55.4167 14.5833 55.4167ZM31.5 26.25L38.5 19.25L40.8333 21.5833C44.625 25.375 49.5833 27.7083 55.7083 27.7083V21.875C51.3333 21.875 47.8333 20.125 45.2083 17.5L39.6667 11.9583C38.2083 10.7917 36.75 10.2083 35 10.2083C33.25 10.2083 31.7917 10.7917 30.9167 11.9583L22.75 20.125C21.5833 21.2917 21 22.75 21 24.2083C21 25.9583 21.5833 27.4167 22.75 28.2917L32.0833 36.4583V51.0417H37.9167V32.9583L31.5 26.25ZM55.4167 30.625C47.25 30.625 40.8333 37.0417 40.8333 45.2083C40.8333 53.375 47.25 59.7917 55.4167 59.7917C63.5833 59.7917 70 53.375 70 45.2083C70 37.0417 63.5833 30.625 55.4167 30.625ZM55.4167 55.4167C49.875 55.4167 45.2083 50.75 45.2083 45.2083C45.2083 39.6667 49.875 35 55.4167 35C60.9583 35 65.625 39.6667 65.625 45.2083C65.625 50.75 60.9583 55.4167 55.4167 55.4167Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Music & Movies")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Music & Movies")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.musicMovies' | translate}}</span>
    <svg class='interests__svg' width='68' height='70' viewBox='0 0 68 70'>
      <path
        d='M33.5211 0C15.01 0 0 14.8474 0 33.1579V58.9474C0 65.0632 4.99092 70 11.1737 70H22.3474V40.5263H7.44914V33.1579C7.44914 18.9 19.107 7.36842 33.5211 7.36842C47.9352 7.36842 59.5931 18.9 59.5931 33.1579V40.5263H44.6948V70H55.8685C62.0513 70 67.0423 65.0632 67.0423 58.9474V33.1579C67.0423 14.8474 52.0322 0 33.5211 0Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Books, Magazines & Games")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Books, Magazines & Games")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.booksMagazinesGames' | translate}}</span>
    <svg class='interests__svg' width='70' height='70' viewBox='0 0 70 70'>
      <path
        d='M61.6667 33.3333H56.6667V20C56.6667 16.3333 53.6667 13.3333 50 13.3333H36.6667V8.33333C36.6667 3.73333 32.9333 0 28.3333 0C23.7333 0 20 3.73333 20 8.33333V13.3333H6.66667C3 13.3333 0.0333333 16.3333 0.0333333 20V32.6667H5C9.96667 32.6667 14 36.7 14 41.6667C14 46.6333 9.96667 50.6667 5 50.6667H0V63.3333C0 67 3 70 6.66667 70H19.3333V65C19.3333 60.0333 23.3667 56 28.3333 56C33.3 56 37.3333 60.0333 37.3333 65V70H50C53.6667 70 56.6667 67 56.6667 63.3333V50H61.6667C66.2667 50 70 46.2667 70 41.6667C70 37.0667 66.2667 33.3333 61.6667 33.3333Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Education & Technology")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Education & Technology")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.educationTechnology' | translate}}</span>
    <svg class='interests__svg' width='70' height='70' viewBox='0 0 70 70'>
      <path
        d='M46.6667 23.3333H23.3333V46.6667H46.6667V23.3333ZM38.8889 38.8889H31.1111V31.1111H38.8889V38.8889ZM70 31.1111V23.3333H62.2222V15.5556C62.2222 11.2778 58.7222 7.77778 54.4444 7.77778H46.6667V0H38.8889V7.77778H31.1111V0H23.3333V7.77778H15.5556C11.2778 7.77778 7.77778 11.2778 7.77778 15.5556V23.3333H0V31.1111H7.77778V38.8889H0V46.6667H7.77778V54.4444C7.77778 58.7222 11.2778 62.2222 15.5556 62.2222H23.3333V70H31.1111V62.2222H38.8889V70H46.6667V62.2222H54.4444C58.7222 62.2222 62.2222 58.7222 62.2222 54.4444V46.6667H70V38.8889H62.2222V31.1111H70ZM54.4444 54.4444H15.5556V15.5556H54.4444V54.4444Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Online Shopping & Entertainment")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Online Shopping & Entertainment")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.onlineShoppingEntertainment' | translate}}</span>
    <svg class='interests__svg' width='70' height='70' viewBox='0 0 70 70'>
      <path
        d='M21 56C17.15 56 14.035 59.15 14.035 63C14.035 66.85 17.15 70 21 70C24.85 70 28 66.85 28 63C28 59.15 24.85 56 21 56ZM0 0V7H7L19.6 33.565L14.875 42.14C14.315 43.12 14 44.275 14 45.5C14 49.35 17.15 52.5 21 52.5H63V45.5H22.47C21.98 45.5 21.595 45.115 21.595 44.625L21.7 44.205L24.85 38.5H50.925C53.55 38.5 55.86 37.065 57.05 34.895L69.58 12.18C69.86 11.69 70 11.095 70 10.5C70 8.575 68.425 7 66.5 7H14.735L11.445 0H0ZM56 56C52.15 56 49.035 59.15 49.035 63C49.035 66.85 52.15 70 56 70C59.85 70 63 66.85 63 63C63 59.15 59.85 56 56 56Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Photography")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Photography")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.photography' | translate}}</span>
    <svg class='interests__svg' width='70' height='63' viewBox='0 0 70 63'>
      <path
        d='M35 47C41.0751 47 46 41.8513 46 35.5C46 29.1487 41.0751 24 35 24C28.9249 24 24 29.1487 24 35.5C24 41.8513 28.9249 47 35 47Z' />
      <path
        d='M24.5 0L18.095 7H7C3.15 7 0 10.15 0 14V56C0 59.85 3.15 63 7 63H63C66.85 63 70 59.85 70 56V14C70 10.15 66.85 7 63 7H51.905L45.5 0H24.5ZM35 52.5C25.34 52.5 17.5 44.66 17.5 35C17.5 25.34 25.34 17.5 35 17.5C44.66 17.5 52.5 25.34 52.5 35C52.5 44.66 44.66 52.5 35 52.5Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Food, Lifestyle & Events")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Food, Lifestyle & Events")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.foodLifestyleEvents' | translate}}</span>
    <svg class='interests__svg' width='71' height='70' viewBox='0 0 71 70'>
      <path
        d='M54.3065 70H59.5907C62.2647 70 64.4611 67.9627 64.7794 65.3524L70.0318 12.8922H54.1155V0H47.8445V12.8922H32.0236L32.9786 20.3411C38.422 21.8372 43.5152 24.543 46.5712 27.5352C51.1551 32.0555 54.3065 36.7349 54.3065 44.3747V70ZM0 66.8167V63.6653H47.8445V66.8167C47.8445 68.5675 46.412 70 44.6294 70H3.2151C1.43247 70 0 68.5675 0 66.8167ZM47.8445 44.5339C47.8445 19.0678 0 19.0678 0 44.5339H47.8445ZM0.0636652 50.9322H47.8126V57.2988H0.0636652V50.9322Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Travel & Cars")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Travel & Cars")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.travelCars' | translate}}</span>
    <svg class='interests__svg' width='67' height='70' viewBox='0 0 67 70'>
      <path
        d='M66.5 49V42L38.5 24.5V5.25C38.5 2.345 36.155 0 33.25 0C30.345 0 28 2.345 28 5.25V24.5L0 42V49L28 40.25V59.5L21 64.75V70L33.25 66.5L45.5 70V64.75L38.5 59.5V40.25L66.5 49Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Animals")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Animals")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.animals' | translate}}</span>
    <svg class='interests__svg' width='70' height='67' viewBox='0 0 70 67'>
      <path
        d='M8.5 32C13.1944 32 17 27.9706 17 23C17 18.0294 13.1944 14 8.5 14C3.80558 14 0 18.0294 0 23C0 27.9706 3.80558 32 8.5 32Z' />
      <path
        d='M24.5 18C29.1944 18 33 13.9706 33 9C33 4.02944 29.1944 0 24.5 0C19.8056 0 16 4.02944 16 9C16 13.9706 19.8056 18 24.5 18Z' />
      <path
        d='M45.5 18C50.1944 18 54 13.9706 54 9C54 4.02944 50.1944 0 45.5 0C40.8056 0 37 4.02944 37 9C37 13.9706 40.8056 18 45.5 18Z' />
      <path
        d='M61.5 32C66.1944 32 70 27.9706 70 23C70 18.0294 66.1944 14 61.5 14C56.8056 14 53 18.0294 53 23C53 27.9706 56.8056 32 61.5 32Z' />
      <path
        d='M53.7265 41.5384C50.6806 37.9032 48.1248 34.8027 45.0439 31.1676C43.4334 29.2431 41.3678 27.3186 38.9171 26.4633C38.532 26.3207 38.1469 26.2138 37.7618 26.1426C36.8865 26 35.9412 26 35.031 26C34.1207 26 33.1754 26 32.2652 26.1782C31.88 26.2495 31.4949 26.3564 31.1098 26.4989C28.6591 27.3543 26.6285 29.2787 24.983 31.2032C21.9371 34.8383 19.3814 37.9389 16.3005 41.574C11.7141 46.2427 6.07745 51.4102 7.12776 58.6448C8.14306 62.28 10.6988 65.8794 15.2852 66.913C17.8409 67.4475 25.9983 65.3449 34.6809 65.3449H35.3111C43.9936 65.3449 52.151 67.4119 54.7068 66.913C59.2931 65.8794 61.8489 62.2443 62.8642 58.6448C63.9495 51.3746 58.3128 46.207 53.7265 41.5384Z' />
    </svg>
  </div>

  <div (click)='toggleInterests("Social & Family")' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest("Social & Family")}'>
    <span class='interests__text'>{{ 'newCampaign.interests.socialFamily' | translate}}</span>
    <svg class='interests__svg' width='67' height='70' viewBox='0 0 67 70'>
      <path
        d='M38.5 0V28H66.5C66.5 12.53 53.97 0 38.5 0ZM60.62 48.615C64.295 43.89 66.5 37.94 66.5 31.5H15.54L12.215 24.5H0V31.5H7.77C7.77 31.5 14.385 45.745 15.19 46.97C11.34 49.035 8.75 53.095 8.75 57.75C8.75 64.505 14.245 70 21 70C27.16 70 32.27 65.45 33.11 59.5H40.39C41.23 65.45 46.34 70 52.5 70C59.255 70 64.75 64.505 64.75 57.75C64.75 54.11 63.14 50.855 60.62 48.615ZM21 63C18.095 63 15.75 60.655 15.75 57.75C15.75 54.845 18.095 52.5 21 52.5C23.905 52.5 26.25 54.845 26.25 57.75C26.25 60.655 23.905 63 21 63ZM52.5 63C49.595 63 47.25 60.655 47.25 57.75C47.25 54.845 49.595 52.5 52.5 52.5C55.405 52.5 57.75 54.845 57.75 57.75C57.75 60.655 55.405 63 52.5 63Z' />
    </svg>
  </div>
</div>
<div class='tile' [ngClass]='isTabs ? "two-tabs": ""' *ngIf='linkedinInterests'>
  <div *ngFor='let interest of linkedinInterests' (click)='toggleInterests(interest)' class='tile__box interests small'
       [ngClass]='{"active" : checkInterest(interest)}'>
    <span class='interests__text'>{{'newCampaign.linkedinInterests.' + getKeyNameForTranslate(interest) | translate}}</span>
  </div>
</div>
