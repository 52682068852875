import * as brandActions from './brand.actions';
import { createReducer, on, Action } from '@ngrx/store';
import * as appActions from '@store/app.actions';
import { BrandModel } from '@app/shared/model/brand.model';

const initialState = null;

const brandReducerInternal = createReducer(
  initialState,

  on(
    brandActions.getBrandSuccess,
    brandActions.updateBrandInfoSuccess,
    brandActions.createNewBrandSuccess,
    (state, { brand }) => {
      return {
        ...brand,
      };
    }
  ),

  on(brandActions.updateBrandFunds, (state, { addAmount }) => {
    return {
      ...state,
      funds: (state.funds + addAmount).toFixed(2),
    };
  }),

  on(appActions.logOutUser, () => initialState)
);

export function brandReducer(state: BrandModel | null, action: Action): BrandModel {
  return brandReducerInternal(state, action);
}
