import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignOverviewState } from '../index';
export const getCampaignOverviewFeatureState = createFeatureSelector<CampaignOverviewState>('campaignOverview');

export const selectCampaignOverview = createSelector(
  getCampaignOverviewFeatureState,
  (state: CampaignOverviewState) => state
);

export const selectCampaignOnly = createSelector(
  getCampaignOverviewFeatureState,
  (state: CampaignOverviewState) => state.campaign
);

export const selectCampaignTags = createSelector(
  getCampaignOverviewFeatureState,
  (state: CampaignOverviewState) => state.campaign.preTags
);

export const selectCampaignPosts = createSelector(
  getCampaignOverviewFeatureState,
  (state: CampaignOverviewState) => state.campaignPosts
);

export const selectCampaignAnalytics = createSelector(
  selectCampaignOverview,
  (state: CampaignOverviewState) => state.campaignAnalytics
);
