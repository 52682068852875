import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'app-post-decline-change',
  templateUrl: './brand-post-decline.component.html',
})
export class BrandPostDeclineComponent implements OnInit {
  declineReason: string;
  declineReasonsModel = 'default';
  declineReasons: string[];

  constructor(private translate: TranslateService, private ref: DialogRef) {}

  ngOnInit(): void {
    this.declineReasons = this.translate.instant('campaignOverview.post.dialog.declineReasons');

    if (this.ref.data.feedback) {
      this.declineReason = this.ref.data.feedback;
    }
  }

  reasonChange(event): void {
    this.declineReason = event.target.value;
  }
}
