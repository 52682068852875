import { createReducer, on, Action } from '@ngrx/store';
import * as campaignDraftActions from './campaign-draft.actions';
import { CampaignModel } from '@app/shared/model';

const campaignDraftReducerInternal = createReducer(
  null,

  on(campaignDraftActions.patchDraftCampaign, (state, { campaign }) => {
    return {
      ...state,
      ...campaign,
    };
  }),

  on(campaignDraftActions.createCampaignDraft, (state, { campaign }) => {
    return {
      ...campaign,
    };
  }),

  on(campaignDraftActions.deleteCampaignDraft, () => null)
);

export function campaignDraftReducer(state: CampaignModel | null, action: Action): CampaignModel {
  return campaignDraftReducerInternal(state, action);
}
