<footer>
  <div class="footer wrapper">
    <div class="footer__row">
    </div>

    <div class="footer__row">
      <div class="footer__colum">
      </div>
      <div class="footer__colum">
        <span class="footer__text">© 2023 MediAdx Ltd, Advance Business Centre, 3 Triq G. Flores, Santa Venera, SVR1950, Malta.</span>
      </div>
    </div>
  </div>
</footer>
