import { createAction, props } from '@ngrx/store';
import { UserModel } from '@app/shared/model/user.model';
import { HttpResponse } from '@angular/common/http';

export enum UserActionTypes {
  GET_USER = '[User] Get User',
  GET_USER_SUCCESS = '[User] Get User Success',
  GET_USER_FAIL = '[User] Get User Fail',

  UPDATE_USER_INFO = '[User] Update user info',
  UPDATE_USER_INFO_SUCCESS = '[User] Update user info success',
  UPDATE_USER_INFO_FAIL = '[User] Update user info fail',

  CREATE_NEW_USER = '[User] Create new user',
  CREATE_NEW_USER_SUCCESS = '[User] Create new user success',
  CREATE_NEW_USER_FAIL = '[User] Create new user info fail',
}

export const getUser = createAction(UserActionTypes.GET_USER);
export const getUserSuccess = createAction(
  UserActionTypes.GET_USER_SUCCESS,
  props<{ profile: UserModel; isLoaded: boolean }>()
);

export const updateUserInfo = createAction(UserActionTypes.UPDATE_USER_INFO, props<{ user: Partial<UserModel> }>());
export const updateUserInfoSuccess = createAction(UserActionTypes.UPDATE_USER_INFO_SUCCESS);
export const updateUserInfoFail = createAction(UserActionTypes.UPDATE_USER_INFO_FAIL);

export const createNewUser = createAction(UserActionTypes.CREATE_NEW_USER, props<{ user: Partial<UserModel> }>());
export const createNewUserSuccess = createAction(
  UserActionTypes.CREATE_NEW_USER_SUCCESS,
  props<{ profile: UserModel; isLoaded: boolean }>()
);
export const createNewUserFail = createAction(
  UserActionTypes.CREATE_NEW_USER_FAIL,
  props<{ error: HttpResponse<Error> }>()
);
