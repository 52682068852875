<section class="typical-page">
  <div class="wrapper">
    <div class="typical-page__block">

      <span class="modal__title">{{'changePassword.changePasswordTitle' | translate}}</span>

      <form [formGroup]="changePasswordForm" class="form form--w-740" style='flex-direction: column'>

        <div class='select-box'>
          <div class="form__box" [class.error]="changePasswordForm.get('password').invalid && changePasswordForm.get('password').touched">
            <span class="form__title">New password</span>
            <label>
              <input type="password" class="form__input" formControlName='password'>
            </label>
            <span class='form__error' *ngIf="!changePasswordForm.get('password').valid && changePasswordForm.get('password').touched">
            {{changePasswordForm.controls.password.errors?.errorFromApi? changePasswordForm.controls.password.errors?.errorFromApi : 'This field can not be empty!'}}
          </span>
          </div>


          <div class="form__box" [class.error]="changePasswordForm.get('confirm').invalid && changePasswordForm.get('confirm').touched">
            <span class="form__title">Confirm new password</span>
            <label>
              <input type="password" class="form__input" formControlName='confirm'>
            </label>
            <span *ngIf="!changePasswordForm.get('confirm')?.errors?.notEquivalent" class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
            <span *ngIf="changePasswordForm.get('confirm')?.errors?.notEquivalent" class="form__error">{{'newCampaign.submitMsg.passwordMatch' | translate}}</span>
          </div>
        </div>

        <button style="align-self: center; margin-top: 30px" (click)='changePassword()' type="submit" [disabled]="!changePasswordForm.valid" class="btn big primary-btn-filled" >
          <span class="btn__text">{{'changePassword.changePasswordTitle' | translate }}</span>
        </button>

      </form>



<!--      <img src="assets/img/video-box_bg.jpg" alt="" class="typical-page__bg">-->
    </div>
  </div>
</section>
