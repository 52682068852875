import { createAction, props } from '@ngrx/store';
import { BrandModel } from '@app/shared/model/brand.model';

export enum BrandActionTypes {
  GET_BRAND = '[Brand] Get Brand',
  GET_BRAND_SUCCESS = '[Brand] Get Brand Success',
  GET_BRAND_FAIL = '[Brand] Get Brand Fail',

  UPDATE_BRAND_INFO = '[Brand] Update brand info',
  UPDATE_BRAND_INFO_SUCCESS = '[Brand] Update brand info success',
  UPDATE_BRAND_INFO_FAIL = '[Brand] Update brand info fail',

  CREATE_NEW_BRAND = '[Brand] Create new brand',
  UPDATE_BRAND_FUNDS = '[Brand] Update brand funds',
  CREATE_NEW_BRAND_SUCCESS = '[Brand] Create new brand success',
}

export const getBrand = createAction(BrandActionTypes.GET_BRAND);
export const getBrandSuccess = createAction(BrandActionTypes.GET_BRAND_SUCCESS, props<{ brand: BrandModel }>());
export const getBrandFail = createAction(BrandActionTypes.GET_BRAND_FAIL);

export const updateBrandInfo = createAction(
  BrandActionTypes.UPDATE_BRAND_INFO,
  props<{ brand: Partial<BrandModel> }>()
);
export const updateBrandFunds = createAction(BrandActionTypes.UPDATE_BRAND_FUNDS, props<{ addAmount: number }>());
export const updateBrandInfoSuccess = createAction(
  BrandActionTypes.UPDATE_BRAND_INFO_SUCCESS,
  props<{ brand: BrandModel }>()
);
export const updateBrandInfoFail = createAction(BrandActionTypes.UPDATE_BRAND_INFO_FAIL);
export const createNewBrand = createAction(BrandActionTypes.CREATE_NEW_BRAND, props<{ brand: Partial<BrandModel> }>());
export const createNewBrandSuccess = createAction(
  BrandActionTypes.CREATE_NEW_BRAND_SUCCESS,
  props<{ brand: BrandModel }>()
);
