import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { deleteCampaignDraft, getCampaignDraft } from '@app/store';
import { AppState } from '@store/app.state';
import { DialogService } from '@ngneat/dialog';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit, OnDestroy {
  constructor(private store: Store<AppState>, private dialog: DialogService, private translate: TranslateService) {}

  ngOnInit(): void {
    // this.store.dispatch(getCampaignDraft());
  }

  discardChanges(): Observable<boolean> {
    const LeavePageChangeText = this.translate.instant('newCampaign.leavePageConfirmMsg');
    const dialogRef = this.dialog.confirm(LeavePageChangeText);
    return dialogRef.afterClosed$;
  }

  ngOnDestroy(): void {
    this.store.dispatch(deleteCampaignDraft());
  }
}
