import { createReducer, on, Action } from '@ngrx/store';
import * as campaignOverviewActions from './campaign-overview.actions';
import { CampaignModel, PostModel } from '@app/shared/model';
import { CampaignAnalyticsM, CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';

export interface CampaignOverviewState {
  campaign: CampaignModel | null;
  campaignPosts: CampaignPostsState;
  campaignAnalytics: CampaignAnalyticsM | null;
}

export interface CampaignPostsState {
  posts: PostModel[] | null;
  isLoaded: boolean;
}

export const initialCampaignOverviewState: CampaignOverviewState = {
  campaign: null,
  campaignPosts: {
    posts: null,
    isLoaded: false,
  },
  campaignAnalytics: null,
};

const campaignOverviewReducerInternal = createReducer(
  initialCampaignOverviewState,
  on(campaignOverviewActions.getSingleCampaignSuccess, (state, { campaign }) => {
    return {
      ...state,
      campaign,
    };
  }),

  on(campaignOverviewActions.getSingleCampaignPostsSuccess, (state, { posts, isLoaded }) => {
    return {
      ...state,
      campaignPosts: { posts, isLoaded },
    };
  }),

  on(campaignOverviewActions.getSingleCampaignAnalyticsSuccess, (state, { analytics }) => {
    return {
      ...state,
      campaignAnalytics: analytics,
    };
  }),

  on(campaignOverviewActions.postSelection, (state, { postId, selected }) => {
    return {
      ...state,
      campaignPosts: {
        ...state.campaignPosts,
        posts: state.campaignPosts.posts.map((post, i) => (post.id === postId ? { ...post, selected } : post)),
      },
    };
  }),

  on(campaignOverviewActions.makePostSeenSuccess, (state, { postId }) => {
    return {
      ...state,
      campaignPosts: {
        ...state.campaignPosts,
        posts: state.campaignPosts.posts.map((post, i) =>
          post.id === postId ? { ...post, seen: true, updated: false } : post
        ),
      },
    };
  }),

  on(campaignOverviewActions.postStatusUpdatedSuccess, (state, { postId, status, feedback }) => {
    return {
      ...state,
      campaignPosts: {
        ...state.campaignPosts,
        posts: state.campaignPosts.posts.map((post, i) =>
          post.id === postId ? { ...post, status, brandFeedback: feedback } : post
        ),
      },
    };
  }),

  on(campaignOverviewActions.resetPostsSelection, (state) => {
    return {
      ...state,
      campaignPosts: {
        ...state.campaignPosts,
        posts: state.campaignPosts.posts.map((post, i) => ({ ...post, selected: false })),
      },
    };
  }),

  on(campaignOverviewActions.deleteSingleCampaign, () => {
    return {
      ...initialCampaignOverviewState,
    };
  })
);

export function campaignOverviewReducer(state: CampaignOverviewState | null, action: Action): CampaignOverviewState {
  return campaignOverviewReducerInternal(state, action);
}
