import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore.default(Highcharts);

@Component({
  selector: 'app-column-chart',
  template: `
    <highcharts-chart
      [Highcharts]='Highcharts'
      [options]='chartOptions'
      style="width: 100%; display: block;"
    >
    </highcharts-chart>`,
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent implements OnInit, OnChanges {
  @Input() data: { y: number; name: string }[];
  @Input() colorStart: string;
  @Input() colorEnd: string;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  constructor() { }

  ngOnInit(): void {
    this.initChart(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data.isFirstChange()) {
      this.initChart(this.data);
    }
  }

  private initChart(data): void{
    this.chartOptions = {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'font-family: \'OpenSans\', sans-serif',
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: ''
        }

      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}%'
          },
        },
      },

      tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },

      series: [
        {
          type: 'column',
          name: 'Browsers',
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [[0, this.colorStart], [1, this.colorEnd]]
          },
          data
        }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 1300
          },
          chartOptions: {
            yAxis: {
              labels: {
                align: 'left',
                x: 0,
                y: -5
              },
              title: {
                text: null
              }
            },
            subtitle: {
              text: null
            },
            credits: {
              enabled: false
            }
          }
        }]
      }
    };
  }
}


