import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InviteDialogComponent } from '@app/shared-features/invite-dialog/invite-dialog.component';

@NgModule({
  declarations: [InviteDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  exports: [InviteDialogComponent],
})
export class InviteDialogModule { }
