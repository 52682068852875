<header>
  <div class="wrapper">
    <div class="header header-left">
      <a routerLink='/' class="logo">
        <img class="logo__img" src="assets/img/logo_iol.png" alt="">
<!--        <span class="logo__text">flytrendy</span>-->
      </a>

      <div *ngIf='!isFeedbackPage' class="header__block" [ngClass]='{"active": openedMenu}'>

        <button type="button" class="btn-text primary-btn-text" (click)='openLoginDialog(); openedMenu = false;'>
          <span class="btn-text__text">Login</span>
          <svg class="btn-text__svg" width="17" height="20" viewBox="0 0 17 20" fill="currentColor">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49446 20C2.83149 17.5701 0 15.1458 0 12.7273V3.63636C0 2.93855 0.278051 2.77638 1.04758 2.32754C1.28058 2.19164 1.55863 2.02946 1.88766 1.81818C2.04036 1.72013 5.25072 0 8.49446 0C11.3215 0 13.6855 0.909091 15.1013 1.81818C15.4303 2.02946 15.7083 2.19164 15.9413 2.32754C16.7109 2.77638 16.9889 2.93855 16.9889 3.63636C17.0075 3.83384 16.9984 8.03319 16.9925 10.7219V10.7225L16.9925 10.7258C16.9905 11.6411 16.9889 12.3809 16.9889 12.7273C16.9889 15.1515 14.1574 17.5758 8.49446 20ZM15.1013 12.7272C15.1013 12.5178 15.1018 12.167 15.103 11.5951L15.1068 9.81941C15.108 9.24495 15.109 8.75198 15.1098 8.28155C15.1133 6.25233 15.1123 4.51068 15.1103 3.97201L14.9806 3.89451C14.6754 3.71213 14.2776 3.47443 14.0542 3.33093C12.6608 2.43622 10.6277 1.81812 8.49446 1.81812C6.45398 1.81812 3.80234 2.77382 2.93474 3.33093C2.71128 3.47443 2.31348 3.71213 2.00827 3.8945L1.88766 3.9666V12.7272C1.88766 14.0985 3.99155 15.9715 8.49533 18.0017C12.9986 15.9761 15.1013 14.1038 15.1013 12.7272ZM11.6023 6.62988L7.55058 10.5325L5.38649 8.44806L4.05171 9.73371L7.55058 13.1038L12.9371 7.91553L11.6023 6.62988Z"/>
          </svg>
        </button>

        <div class="soc-seti">
          <a [href]="socialLinks.facebook" class="soc-seti__link">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99121 0H18.9912C20.0958 0 20.9912 0.89543 20.9912 2V18C20.9912 19.1046 20.0958 20 18.9912 20H2.99121C1.88664 20 0.991211 19.1046 0.991211 18V2C0.991211 0.89543 1.88664 0 2.99121 0ZM2.99121 2V18H18.9912V2H2.99121ZM10.1243 10.0044H11.9912V16H13.9912V10.0044H15.9736V8.00439H13.9912V7C13.9912 6.44772 14.4389 6 14.9912 6H15.9912V4H14.9912C13.3344 4 11.9912 5.34315 11.9912 7V8.00439H10.1243V10.0044Z"/>
            </svg>
          </a>
          <a [href]="socialLinks.instagram" class="soc-seti__link">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9912 0H6.99121C3.6775 0 0.991211 2.68629 0.991211 6V14C0.991211 17.3137 3.6775 20 6.99121 20H14.9912C18.3049 20 20.9912 17.3137 20.9912 14V6C20.9912 2.68629 18.3049 0 14.9912 0ZM2.99121 6C2.99121 3.79086 4.78207 2 6.99121 2H14.9912C17.2003 2 18.9912 3.79086 18.9912 6V14C18.9912 16.2091 17.2003 18 14.9912 18H6.99121C4.78207 18 2.99121 16.2091 2.99121 14V6ZM10.9912 15C8.22979 15 5.99121 12.7614 5.99121 10C5.99121 7.23858 8.22979 5 10.9912 5C13.7526 5 15.9912 7.23858 15.9912 10C15.9912 12.7614 13.7526 15 10.9912 15ZM10.9912 13C12.6481 13 13.9912 11.6569 13.9912 10C13.9912 8.34315 12.6481 7 10.9912 7C9.33436 7 7.99121 8.34315 7.99121 10C7.99121 11.6569 9.33436 13 10.9912 13ZM16.9912 5C16.9912 5.55228 16.5435 6 15.9912 6C15.4389 6 14.9912 5.55228 14.9912 5C14.9912 4.44772 15.4389 4 15.9912 4C16.5435 4 16.9912 4.44772 16.9912 5Z"/>
            </svg>
          </a>
          <a [href]="socialLinks.linkedin" class="soc-seti__link">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99121 0H18.9912C20.0958 0 20.9912 0.89543 20.9912 2V18C20.9912 19.1046 20.0958 20 18.9912 20H2.99121C1.88664 20 0.991211 19.1046 0.991211 18V2C0.991211 0.89543 1.88664 0 2.99121 0ZM2.99121 2V18H18.9912V2H2.99121ZM11.9912 7C11.4735 7 10.9265 7.15826 10.4434 7.45215L9.99121 7H8.99121V14H10.9912V10C10.9912 9.42425 11.5852 9 11.9912 9H12.9912C13.3973 9 13.9912 9.42425 13.9912 10V14H15.9912V10C15.9912 8.14718 14.3852 7 12.9912 7H11.9912ZM6.99121 6C7.5435 6 7.99121 5.55228 7.99121 5C7.99121 4.44772 7.5435 4 6.99121 4C6.43893 4 5.99121 4.44772 5.99121 5C5.99121 5.55228 6.43893 6 6.99121 6ZM5.99121 7V14H7.99121V7H5.99121Z"/>
            </svg>
          </a>
          <a [href]="socialLinks.youtube" class="soc-seti__link">
            <svg width="21" height="16" viewBox="0 0 21 16" fill="currentColor">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1036 13.5713C20.7248 12.5068 20.9902 10.7901 20.9902 7.99772C20.9902 5.21643 20.7273 3.5098 20.0995 2.41666C19.4704 1.28151 18.7241 0.921204 17.2432 0.834722C16.2322 0.766196 13.7196 0.727539 10.9927 0.727539C8.25988 0.727539 5.74609 0.766181 4.72751 0.835259C3.25489 0.92141 2.50852 1.28251 1.87381 2.42081C1.25513 3.51018 0.990234 5.22515 0.990234 8.0079C0.990234 10.7748 1.25641 12.4985 1.87925 13.5842C2.5057 14.7079 3.24017 15.0646 4.72533 15.1689C5.79274 15.2314 8.4488 15.273 10.9927 15.273C13.5308 15.273 16.1856 15.2314 17.2432 15.1696C18.7421 15.0647 19.4764 14.7073 20.1036 13.5713ZM17.1288 2.64926C18.0551 2.70339 18.2269 2.78665 18.5205 3.3181C18.948 4.05842 19.1721 5.50726 19.1721 8.00788C19.1721 10.4896 18.9468 11.9464 18.5225 12.6739C18.2279 13.207 18.0573 13.29 17.1267 13.3552C16.1256 13.4136 13.4966 13.4548 10.9927 13.4548C8.48308 13.4548 5.85273 13.4136 4.84208 13.3545C3.92297 13.2899 3.74992 13.2059 3.46181 12.6892C3.03465 11.9444 2.80842 10.4794 2.80842 7.9977C2.80842 5.51751 3.03345 4.0606 3.45832 3.31245C3.75091 2.78776 3.92518 2.70345 4.84214 2.64978C5.81303 2.58398 8.30318 2.5457 10.9927 2.5457C13.6764 2.5457 16.1653 2.58399 17.1288 2.64926ZM9.17212 5.27246L13.7176 7.99973L9.17212 10.727V5.27246Z"/>
            </svg>
          </a>
        </div>
      </div>

      <div class="menu-btn" (click)='toggleMenu()'>
        <div class="menu-btn__hamburger" [ngClass]='{"active": openedMenu}'></div>
      </div>

      <div class="overlay-menu"></div>
    </div>
  </div>
</header>
