<div class="modal-block modal-brand-login" style='max-width: 1200px'>
  <div class="modal__cont">
    <h3>{{'insights.scoreDialog.title' | translate}}</h3>

    <div class="select-box" style='padding-left: 30px; padding-top: 20px'>
      <ol style='list-style-type: decimal'>
        <li>{{'insights.scoreDialog.item1' | translate}}</li>
        <li>{{'insights.scoreDialog.item2' | translate}}</li>
        <li>{{'insights.scoreDialog.item3' | translate}}</li>
        <li>{{'insights.scoreDialog.item4' | translate}}</li>
        <li>{{'insights.scoreDialog.item5' | translate}}</li>
      </ol>
    </div>
  </div>
</div>
