import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { getCampaignDraft, selectCampaignDraft } from '@app/store';
import { CampaignModel, SocialPlatforms } from '@app/shared/model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as campaignDraftActions from '@store/campaign-draft';
import { Actions, ofType } from '@ngrx/effects';
import { HelperService } from '@app/services/helper.service';

@Component({
  selector: 'app-brief-step',
  templateUrl: './brief-step.component.html',
  styleUrls: ['./brief-step.component.scss'],
})
export class BriefStepComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  stepTitle = 'brief';
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);
  pubGuides: string[];
  dontDos: string[];
  moodImages: any[] = [];
  imageIndex = null;

  moodImagesItems = Array(6) // @ts-ignore
    .fill()
    .map((x, i) => i);

  dosInput = '';
  dontInput = '';

  briefForm = this.fb.group(
    {
      contentLove: ['', [Validators.required]],
      callAction: ['', [Validators.required]],
      instagram: [''],
      facebook: [''],
      tiktok: [''],
      linkedin: [''],
      dosInput: [''],
      dontInput: [''],
    },
    {
      updateOn: 'change',
    }
  );
  contentLove = this.briefForm.get('contentLove');
  callAction = this.briefForm.get('callAction');

  instagram = this.briefForm.get('instagram');
  facebook = this.briefForm.get('facebook');
  tiktok = this.briefForm.get('tiktok');
  linkedin = this.briefForm.get('linkedin');

  socialPlatforms: SocialPlatforms = {
    platformFb: false,
    platformInsta: false,
    platformInstaStory: false,
    platformTiktok: false,
    platformReels: false,
    platformLinkedin: false,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private actions: Actions,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getCampaignDraft());

    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        this.briefForm.patchValue(campaignDraft, { emitEvent: false });
        this.pubGuides = [...campaignDraft?.pubGuides];
        this.dontDos = [...campaignDraft?.dontDos];

        this.briefForm.patchValue({ instagram: campaignDraft.preTags.instagram });
        this.briefForm.patchValue({ facebook: campaignDraft.preTags.facebook });
        this.briefForm.patchValue({ tiktok: campaignDraft.preTags.tiktok });
        this.briefForm.patchValue({ linkedin: campaignDraft.preTags.linkedin });

        if (campaignDraft.socialPlatforms) {
          this.socialPlatforms = campaignDraft.socialPlatforms;
        }

        if (campaignDraft.moodImagesCount || campaignDraft.moodImages?.length) {
          this.getMoodImages(campaignDraft);
        }
      });
  }

  trackByIndex(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getMoodImages(campaign: Partial<CampaignModel>): void {
    if (campaign.moodImages) {
      this.moodImages = campaign.moodImages.slice();
      return;
    }

    if (campaign.moodBoardUrls && campaign.moodBoardUrls.length) {
      campaign.moodBoardUrls.forEach((moodImageUrl) => {
        this.helper
          .base64Converter(moodImageUrl)
          .then((base64Image) => this.moodImages.push({ image: base64Image }))
          .catch((err) => console.error(err));
      });
    }
  }

  fileMoodChangeEvent(e: any, index): void {
    this.imageIndex = index;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e): void {
    const reader = e.target;
    this.moodImages.push({ image: reader.result });
  }

  setSocialPlatform(platform: string): void {
    this.socialPlatforms = {
      ...this.socialPlatforms,
      [platform]: !this.socialPlatforms[platform],
    };
  }

  deleteDontDos(array: string[], index: number): void {
    array.splice(index, 1);
  }

  addDontDos(array: string[], item: AbstractControl): void {
    array.push(item.value);
    item.reset();
  }

  enableNextStep(): boolean {
    const { facebook, tiktok, instagram, linkedin, socialPlatforms } = this;
    const { platformFb, platformInsta, platformTiktok, platformReels, platformLinkedin } = this.socialPlatforms;

    const noSelectedPlatform = Object.values(socialPlatforms).every((value) => !value);

    if (noSelectedPlatform) {
      return false;
    }

    if (!this.briefForm.valid || (platformInsta && !instagram.value)) {
      return false;
    }

    if (!this.briefForm.valid || (platformFb && !facebook.value)) {
      return false;
    }

    if (!this.briefForm.valid || (platformTiktok && !tiktok.value)) {
      return false;
    }

    if (!this.briefForm.valid || (platformLinkedin && !linkedin.value)) {
      return false;
    }

    return true;
  }
  goToPreviousStep(): void {
    this.saveCampaignDraft();
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  goToNextStep(): void {
    this.saveCampaignDraft();
    this.router.navigate(['../influencers'], { relativeTo: this.activatedRoute });
  }

  saveCampaignDraft(): void {
    const {
      facebook,
      tiktok,
      instagram,
      linkedin,
      pubGuides,
      dontDos,
      contentLove,
      callAction,
      socialPlatforms,
    } = this;

    const campaign = {
      contentLove: contentLove.value,
      callAction: callAction.value,
      socialPlatforms,
      preTags: {
        instagram: instagram.value,
        facebook: facebook.value,
        tiktok: tiktok.value,
        linkedin: linkedin.value,
      },
      pubGuides,
      dontDos,
    } as Partial<CampaignModel>;

    campaign.moodImages = this.moodImages;
    // campaign.moodImagesCount = this.moodImages.length;
    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
  }
}
