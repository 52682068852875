<section class="hero hero-left">
  <div class="wrapper">

    <div class="hero__main">
      <div class="selector">
      </div>
      <h1 class="hero__main--title">Piattaforma<br>di Social Creator<br>Amplification<br>con Risultati Garantiti!</h1>
    </div>
    <div class="hero__bottom">
    </div>

    <img src="assets/img/hero-square.png" alt="" class="hero__square-img">
    <img src="assets/img/hero-bg_left.jpeg" alt="" class="hero__bg-ultra-hd">
  </div>
  <img src="assets/img/hero-bg_left.jpeg" alt="" class="hero__bg-full-hd">
</section>
