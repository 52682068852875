import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginDialogComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [LoginDialogComponent],
})
export class LoginDialogModule {}
