<header>
  <div class="wrapper">
    <div class="header header-left">
      <a routerLink='/' class="logo">
        <img class="logo__img" src="assets/img/logo_iol.png" alt="">
<!--        <span class="logo__text">flytrendy</span>-->
      </a>

      <div *ngIf='!isFeedbackPage' class="header__block" [ngClass]='{"active": openedMenu}'>
        <ul class="menu">
          <li class="menu__item">
            <a (click)='goToRoute("campaigns")' class="menu__link">Dashboard</a>
          </li>
        </ul>

        <button routerLink='campaign' type="button" class="btn small primary-btn-filled">
          <span class="btn__text">{{'topBar.createNewCampaign' | translate}}</span>
        </button>

        <div class="header__group">

          <div *ngIf='!isAdditionalUser' class="btn-text campagin-primary-btn-text">
            <span class="btn-text__text">{{(brand$ | async)?.funds}}</span>
            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.3333 17.2222C10.5444 17.2222 8.13333 15.6444 6.93333 13.3333H13.3333V11.1111H6.2C6.14444 10.7444 6.11111 10.3778 6.11111 10C6.11111 9.62222 6.14444 9.25556 6.2 8.88889H13.3333V6.66667H6.93333C8.13333 4.35556 10.5556 2.77778 13.3333 2.77778C15.1222 2.77778 16.7667 3.43333 18.0333 4.52222L20 2.55556C18.2333 0.966667 15.8889 0 13.3333 0C8.97778 0 5.28889 2.78889 3.91111 6.66667H0V8.88889H3.4C3.35556 9.25556 3.33333 9.62222 3.33333 10C3.33333 10.3778 3.35556 10.7444 3.4 11.1111H0V13.3333H3.91111C5.28889 17.2111 8.97778 20 13.3333 20C15.9 20 18.2333 19.0333 20 17.4444L18.0222 15.4778C16.7667 16.5667 15.1333 17.2222 13.3333 17.2222Z"/>
            </svg>
          </div>

          <button (click)='goToRoute("profile")'  routerLink='profile' type="button" class="btn-text campagin-primary-btn-text">
            <span class="btn-text__text" style='text-transform: capitalize'>{{'topBar.myProfile' | translate}}</span>
            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z"/>
            </svg>
          </button>

          <button type="button" class="btn-text campagin-primary-btn-text" (click)='logout($event)'>
            <span class="btn-text__text">Logout</span>
            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7.87778 13.9889L9.44444 15.5556L15 10L9.44444 4.44444L7.87778 6.01111L10.7444 8.88889H0V11.1111H10.7444L7.87778 13.9889ZM17.7778 0H2.22222C0.988889 0 0 1 0 2.22222V6.66667H2.22222V2.22222H17.7778V17.7778H2.22222V13.3333H0V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0Z"/>
            </svg>
          </button>
        </div>

        <div class="soc-seti">
          <a [href]="socialLinks.facebook" class="soc-seti__link" target='_blank'>
            <img src="assets/img/icons/FB.svg" alt="">
          </a>
          <a [href]="socialLinks.instagram" class="soc-seti__link" target='_blank'>
            <img src="assets/img/icons/INST.svg" alt="">
          </a>
          <a [href]="socialLinks.linkedin" class="soc-seti__link" target='_blank'>
            <img src="assets/img/icons/LIN.svg" alt="">
          </a>
          <a [href]="socialLinks.youtube" class="soc-seti__link" target='_blank'>
            <img src="assets/img/icons/YT.svg" alt="">
          </a>
        </div>
      </div>

      <div class="menu-btn" (click)='toggleMenu()'>
        <div class="menu-btn__hamburger" [ngClass]='{"active": openedMenu}'></div>
      </div>

      <div class="overlay-menu"></div>
    </div>
  </div>
</header>
