import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandPostDeclineComponent } from './brand-post-decline.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@ngneat/dialog';

@NgModule({
  declarations: [BrandPostDeclineComponent],
  imports: [CommonModule, TranslateModule, FormsModule, DialogModule],
})
export class BrandPostDeclineModule {}
