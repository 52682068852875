import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BriefStepComponent } from '@app/campaign/brief-step/brief-step.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [BriefStepComponent],
  imports: [
    CommonModule,
    CampaignWizardModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    TranslateModule,
    MatTooltipModule,
  ],
  exports: [BriefStepComponent],
})
export class BriefStepModule {}
