import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagsService } from '@app/services/feature-flag.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string;
  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    const isEnabled = this.featureFlagService.isFeatureEnabled(this.featureFlag);
    if (isEnabled) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
