import { CampaignAnalyticsDto } from '@app/shared/dto/campaign-analytics.dto';
import { CampaignAnalyticsM, CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';

export class CampaignAnalyticsAdapter {
  public static createCampaignAnalytics(analyticsResponse: CampaignAnalyticsDto): CampaignAnalyticsM {
    if (!analyticsResponse) {
      return null;
    }

    const model = new CampaignAnalyticsM();
    model.numberCreators = analyticsResponse.number_of_creators;
    model.numberContents = analyticsResponse.number_of_published_content;
    model.trueReach = analyticsResponse.total_true_reach;
    model.totalEngagement = analyticsResponse.total_engagement;
    model.totalSaved = analyticsResponse.total_saved;
    model.totalViews = analyticsResponse.total_views;
    model.totalShares = analyticsResponse.total_shares;
    model.totalImpressions = analyticsResponse.total_impressions;
    model.totalContent = analyticsResponse.total_content;
    model.breakdownContent = analyticsResponse.breakdown_content;
    model.numberLikes = analyticsResponse.number_likes;
    model.numberComments = analyticsResponse.number_comments;
    model.potentialReachApproved = analyticsResponse.potential_reach_approved;
    model.potentialReachDeclined = analyticsResponse.potential_reach_declined;
    model.potentialReachPublished = analyticsResponse.potential_reach_published;
    model.potentialReachUpdated = analyticsResponse.potential_reach_updated;
    model.potentialReachPending = analyticsResponse.potential_reach_pending;
    model.potentialReachTotal = analyticsResponse.potential_reach;
    return model;
  }
}
