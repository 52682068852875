<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
  <form [formGroup]="campaignForm" class="new-campaign-form" autocomplete="off">
  <div class="new-campaign-submit mt-50 mb-20">
  <span class="new-campaign-form__main-title">{{'newCampaign.submit.details' | translate }}</span>

  <div class="new-campaign-form__wrapper mb-90">
    <div class="new-campaign-submit__colum">
      <div class="form__box" [ngClass]='{"error": checkFieldError("firstName")}'>
        <span class="form__title">{{'myProfile.firstName' | translate }}</span>
        <input formControlName="firstName" type="text" class="form__input" tabindex="1">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("companyName")}'>
        <span class="form__title">{{'newCampaign.submit.companyName' | translate }}</span>
        <input formControlName="companyName" type="text" class="form__input" tabindex="3">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("phoneNumber")}'>
        <span class="form__title">{{'myProfile.phoneNumber' | translate }}</span>
        <input formControlName="phoneNumber" type="text" class="form__input" tabindex="5">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("password")}'>
        <span class="form__title">{{'newCampaign.submit.password' | translate}}</span>
        <input formControlName="password" type="password" class="form__input" autocomplete="new-password" tabindex="7">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>
    </div>

    <div class="new-campaign-submit__colum">
      <div class="form__box" [ngClass]='{"error": checkFieldError("lastName")}'>
        <span class="form__title">{{'myProfile.lastName' | translate }}</span>
        <input formControlName="lastName" type="text" class="form__input" tabindex="2">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("email")}'>
        <span class="form__title">EMAIL</span>
        <input formControlName="email" type="text" class="form__input" tabindex="4">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("mobile")}'>
        <span class="form__title">{{'newCampaign.submit.mobile' | translate}}</span>
        <input formControlName="mobile" type="text" class="form__input" tabindex="6">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("confirmPassword")}'>
        <span class="form__title">{{'newCampaign.submit.confirmPassword' | translate}}</span>
        <input formControlName="confirmPassword" type="password" class="form__input" autocomplete="new-password" tabindex="8">
        <span *ngIf="!campaignForm.get('confirmPassword')?.errors?.notEquivalent" class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
        <span *ngIf="campaignForm.get('confirmPassword')?.errors?.notEquivalent" class="form__error">{{'newCampaign.submitMsg.passwordMatch' | translate}}</span>
      </div>
    </div>
  </div>

  <span class="new-campaign-form__main-title">{{'newCampaign.submit.billingDetails' | translate}}</span>

  <div class="new-campaign-form__wrapper new-campaign-submit__wrapper-colum mb-20">
    <div class="form__box" [ngClass]='{"error": checkFieldError("billingCompany")}'>
      <span class="form__title">{{'newCampaign.submit.billingCompany' | translate}}</span>
      <input formControlName="billingCompany" type="text" class="form__input" tabindex="9">
      <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
    </div>

    <div class="form__box" [ngClass]='{"error": checkFieldError("streetAddress")}'>
      <span class="form__title">{{'newCampaign.submit.streetAddress' | translate}}</span>
      <input formControlName="streetAddress" type="text" class="form__input" tabindex="10">
      <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
    </div>
  </div>

  <div class="new-campaign-form__wrapper mb-20">
    <div class="new-campaign-submit__colum">
      <div class="form__box" [ngClass]='{"error": checkFieldError("city")}'>
        <span class="form__title">{{'newCampaign.submit.city' | translate}}</span>
        <input formControlName="city" type="text" class="form__input" tabindex="11">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("postCode")}'>
        <span class="form__title">{{'newCampaign.submit.postCode' | translate}}</span>
        <input formControlName="postCode" type="text" class="form__input" tabindex="13">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box mt-50" [ngClass]='{"error": checkFieldError("iva")}'>
        <span class="form__title">{{'newCampaign.submit.taxNumber' | translate}}</span>
        <input formControlName="iva" type="text" class="form__input">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box mt-50">
        <span class="form__title">{{'newCampaign.submit.publishBrief' | translate}}</span>

        <!-- to activate the button we use the 'active' class -->
        <div class="new-campaign-form__btn-group">
          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn active" [ngClass]='{"active" : publish.now}'>
            <span class="btn__text">{{'newCampaign.review.now' | translate}}</span>
          </button>

          <button (click)='publish.now = !publish.now; publish.later = !publish.later;' type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : publish.later}'>
            <span class="btn__text">{{'newCampaign.review.later' | translate}}</span>
          </button>
        </div>
      </div>

      <div *ngIf='publish.later' class="form__box mt-30" [ngClass]='{"error": checkFieldError("publishBriefApp")}'>
        <input [min]="todayDate" (click)="pickerBriefDate.open()" [matDatepicker]="pickerBriefDate" type="text" formControlName='publishBriefApp' class="form__input" placeholder="DD/MM/YYYY">
        <mat-datepicker #pickerBriefDate></mat-datepicker>
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>
    </div>

    <div class="new-campaign-submit__colum">
      <div class="form__box" [ngClass]='{"error": checkFieldError("region")}'>
        <span class="form__title">{{'newCampaign.submit.state' | translate}}</span>
        <input formControlName="region" type="text" class="form__input" tabindex="12">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box" [ngClass]='{"error": checkFieldError("country")}'>
        <span class="form__title">{{'newCampaign.submit.country' | translate}}</span>
          <div class="select">
            <label>
              <select formControlName='country' name="countryList" tabindex="14">
                <option *ngFor='let country of countries'>{{country?.country_name}}</option>
              </select>
              <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
            </label>
          </div>
        </div>

      <div class="form__box mt-50" [ngClass]='{"error": checkFieldError("pecUnivoco")}'>
        <span class="form__title">{{'newCampaign.submit.invoice' | translate}}</span>
        <input formControlName="pecUnivoco" type="text" class="form__input">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <div class="form__box mt-50" [ngClass]='{"error": checkFieldError("openUntilDate")}'>
        <span class="form__title">{{'newCampaign.submit.openUntil' | translate}}</span>
        <input [min]="todayDate" (click)="pickerOpenDate.open()" [matDatepicker]="pickerOpenDate" formControlName="openUntilDate" type="text" class="form__input" placeholder="DD/MM/YYYY">
        <mat-datepicker #pickerOpenDate></mat-datepicker>
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>
    </div>
  </div>

  <div class="new-campaign-form__wrapper">
    <div class="new-campaign-submit__colum">
      <div class="form__box">
        <span class="form__title">{{'newCampaign.submit.ccEmail' | translate}}</span>
        <input formControlName="ccEmail" type="text" class="form__input" placeholder="СС@EMAIL.COM">
        <span class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>
    </div>

    <div class="new-campaign-submit__colum">
      <div class="form__box">
        <span class="form__title">Account</span>

        <!-- to activate the button we use the 'active' class -->
        <div class="new-campaign-form__btn-group">
          <button (click)='accountType.brand = !accountType.brand; accountType.agency = !accountType.agency;' type="button" class="btn small campaign-secondary-btn active" [ngClass]='{"active" : accountType.brand}'>
            <span class="btn__text">brand</span>
          </button>

          <button (click)='accountType.brand = !accountType.brand; accountType.agency = !accountType.agency;'  type="button" class="btn small campaign-secondary-btn" [ngClass]='{"active" : accountType.agency}'>
            <span class="btn__text">agency</span>
          </button>
        </div>
      </div>

      <div class="form__box">
        <label class="checked">
          <input (change)="agreePolicy($event)" type="checkbox" class="checked__input">
          <span class="checked__square"></span>
          <span class="checked__text">{{'general.acceptConditions' | translate}} <a href="#" target="_blank">FlyTrendy's Privacy Policy</a></span>
        </label>

        <label class="checked">
          <input (change)="agreeTerms($event)" type="checkbox" class="checked__input">
          <span class="checked__square"></span>
          <span class="checked__text">{{'general.acceptConditions' | translate}} <a href="#" target="_blank">FlyTrendy's T&C's</a></span>
        </label>
      </div>
    </div>
  </div>
</div>
  </form>
</app-campaign-wizard-step>
