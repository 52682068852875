import { createReducer, on, Action } from '@ngrx/store';
import * as campaignActions from './campaigns.actions';
import { CampaignModel } from '@app/shared/model';
import * as campaignDraftActions from '@store/campaign-draft/campaign-draft.actions';

const campaignsReducerInternal = createReducer(
  [],
  on(campaignActions.getCampaignsSuccess, (state, { campaign }) => [...campaign]),

  on(campaignDraftActions.editCampaignDraftSubmitSuccess, (state, { campaign }) => [campaign, ...state])
);

export function campaignsReducer(state: CampaignModel[] | null, action: Action): CampaignModel[] {
  return campaignsReducerInternal(state, action);
}
