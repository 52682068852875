import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FreeDemoComponent } from '@app/landing/dialogs/free-demo/free-demo.component';
import { DialogService } from '@ngneat/dialog';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private renderer: Renderer2;
  constructor(private dialog: DialogService, private location: Location, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openDemoDialog(isBrand: boolean): void {
    this.dialog.open(FreeDemoComponent, {
      closeButton: true,
      data: {
        brand: isBrand,
      },
    });
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  async base64Converter(imageUrl): Promise<any> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
