import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getUser, selectIsLoaded } from '../../store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@store/app.state';
import { DialogService } from '@ngneat/dialog';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../app.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  username = '';
  password = '';
  isLoadingResults = false;
  matcher = new MyErrorStateMatcher();
  isLoaded$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required, Validators.email],
      password: [null, Validators.required],
    });
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      closeButton: true,
    });
  }
  ngOnDestroy(): void {}

  onFormSubmit(): void {
    this.isLoadingResults = true;
    this.authService.login(this.loginForm.value).subscribe(
      () => {
        this.store.dispatch(getUser());
        this.isLoadingResults = false;
        this.router.navigate(['/campaigns']).then((_) => console.log('Logged in'));
      },
      (err: any) => {
        console.log(err);
        this.isLoadingResults = false;
      }
    );
  }
}
