
        <div *ngIf="isLoggedIn; then authorised else nonAuthorized"></div>

        <ng-template #authorised>
         <app-menu-authorized></app-menu-authorized>
        </ng-template>

        <ng-template #nonAuthorized>
          <app-menu></app-menu>
        </ng-template>



<!--<div>-->
<!--  <nav>-->
<!--    <ul>-->
<!--      <li><a routerLink='/campaigns'>Campaigns</a></li>-->
<!--      <li><a routerLink='/profile'>Profile</a></li>-->
<!--      <li><a routerLink='/campaign'>New campaign</a></li>-->
<!--      <li>{{'topBar.guest.contactUs' | translate}}New campaign</li>-->
<!--      <li routerLink='/login'>Login</li>-->
<!--      <li><a (click)="logout($event)" href="#">Logout</a></li>-->
<!--      {{(brand$ | async)?.funds }}-->
<!--    </ul>-->

<!--    <button (click)="useLanguage('en')">en</button>-->
<!--    <button (click)="useLanguage('it')">it</button>-->
<!--  </nav>-->
<!--</div>-->
