import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogRef } from '@ngneat/dialog';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { HelperService } from '@app/services/helper.service';
import { UserService } from '@app/services/user.service';
import { select, Store } from '@ngrx/store';
import { getUser, selectUserInfo } from '@app/store';
import { UserModel } from '@app/shared/model';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private userId: number;
  private username: string;

  changePasswordForm = this.fb.group(
    {
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required]],
    },
    {
      updateOn: 'change',
      validators: [this.checkIfMatchingPasswords('password', 'confirm')],
    }
  );
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private helperService: HelperService,
    private translate: TranslateService,
    private router: Router,
    public ref: DialogRef,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        this.userId = user.id;
        this.username = user.username;
      }
    });
  }

  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return this.helperService.checkIfMatchingPasswords(passwordKey, passwordConfirmationKey);
  }

  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      return;
    }

    const password = this.changePasswordForm.get('password').value;
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');
    const successMsg = this.translate.instant('changePassword.successMsg');

    this.userService
      .updateUserInfo({ id: this.userId, password })
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.authService.login({ username: this.username, password }).pipe(take(1)).subscribe();
        this.dialogRef.close();
      });
  }
}
