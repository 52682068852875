<div class="modal-block modal-brand-login" >
  <div class="modal__cont">
    <span class="modal__title">{{'login.auth' | translate }}</span>

    <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" class="form form--w-740">

      <div class="form__group--two">
        <div class="form__box" [class.error]="loginForm.get('username').invalid && loginForm.get('username').touched">
          <span class="form__title">{{'login.email' | translate }}</span>
          <label>
            <input type="email" class="form__input" formControlName='username' email='true'>
          </label>
          <span class='form__error' *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">Please enter valid Email</span>
        </div>

      </div>

      <div class="form__group--two">
        <div class="form__box" [class.error]="loginForm.get('password').invalid && loginForm.get('password').touched">
          <span class="form__title">{{'login.password' | translate }}</span>
          <label>
            <input type="password" class="form__input" formControlName='password'>
          </label>
          <span class='form__error'  *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Please enter Password</span>
        </div>

        <button (click)='forgotPassword()' type="button" class="btn-text tertiary-btn-text">
          <svg width="17" height="20" viewBox="0 0 17 20" fill="currentColor">
            <path d="M3.24419 7.95563L1.73948 6.45536C0.779033 7.68093 0.181419 9.09667 0 10.5652H2.15568C2.30508 9.64606 2.67859 8.74802 3.24419 7.95563ZM2.15568 12.6783H0C0.181419 14.1469 0.768361 15.5626 1.72881 16.7882L3.23352 15.2879C2.67859 14.4955 2.30508 13.608 2.15568 12.6783ZM3.23352 18.299C4.47144 19.2499 5.91212 19.8204 7.39548 20V17.8553C6.46704 17.6968 5.57062 17.3376 4.77024 16.767L3.23352 18.299ZM9.52982 3.24353V0L4.6742 4.80718L9.52982 9.50872V5.37771C12.5606 5.88484 14.8657 8.48389 14.8657 11.6218C14.8657 14.7596 12.5606 17.3587 9.52982 17.8658V20C13.7451 19.4823 17 15.9324 17 11.6218C17 7.31115 13.7451 3.76123 9.52982 3.24353Z"/>
          </svg>
          <span class="btn-text__text">{{'login.forgotPassword' | translate }}</span>
        </button>
      </div>

      <button type="submit" [disabled]="!loginForm.valid" class="btn big primary-btn-filled" >
        <span class="btn__text" >{{'topBar.guest.login' | translate }}</span>
      </button>

    </form>
    <div class='form_main_error' *ngIf='errorMessage'>{{errorMessage}}</div>
  </div>

<!--  <div class="modal-close" (click)="ref.close()">-->
<!--    <svg width="45" height="45" viewBox="0 0 45 45" fill="currentColor">-->
<!--      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45ZM22.5 40.9091C32.667 40.9091 40.9091 32.6671 40.9091 22.5C40.9091 12.333 32.667 4.09092 22.5 4.09092C12.3329 4.09092 4.09087 12.333 4.09087 22.5C4.09087 32.6671 12.3329 40.9091 22.5 40.9091ZM15.7645 32.1282L22.5 25.3927L29.2354 32.1282L32.1281 29.2355L25.3927 22.5L32.1281 15.7646L29.2354 12.8719L22.5 19.6073L15.7645 12.8719L12.8718 15.7646L19.6073 22.5L12.8718 29.2355L15.7645 32.1282Z"/>-->
<!--    </svg>-->
<!--  </div>-->
</div>
