<section class="campaign mb-100 mt-100">
  <div class="wrapper-small">
    <h2>{{'campaigns.title' | translate}}</h2>
  </div>

  <div class="campaign__cont">

    <div class="wrapper-big search" [ngStyle]="interestsFilter && {'height': '280px'}">
      <div class="wrapper">
        <div class="campaign__top">
          <form [formGroup]="searchForm">
          <input maxlength='80' formControlName='search' type="text" class="form__input" [placeholder]="'campaigns.search' | translate"/>
          </form>
          <div class='interests-button'>
            <button type="button" class="btn-text tertiary-btn-text" (click)="interestsFilter = !interestsFilter">
              <svg class="btn-text__svg-left" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                <path d="M0 15.5556V17.7778H6.66667V15.5556H0ZM0 2.22222V4.44444H11.1111V2.22222H0ZM11.1111 20V17.7778H20V15.5556H11.1111V13.3333H8.88889V20H11.1111ZM4.44444 6.66667V8.88889H0V11.1111H4.44444V13.3333H6.66667V6.66667H4.44444ZM20 11.1111V8.88889H8.88889V11.1111H20ZM13.3333 6.66667H15.5556V4.44444H20V2.22222H15.5556V0H13.3333V6.66667Z"/>
              </svg>
              <span class="btn-text__text">{{'newCampaign.review.interests' | translate}}</span>
            </button>
          </div>
        </div>

        <div class='campaign__top search-interests' *ngIf='interestsFilter'>
          <div class='interests-row'>
            <label [for]=item.id class="checked" *ngFor='let item of interestsArrays?.array1'>
              <input [id]=item.id (change)="changeInterests($event, item.name)" type="checkbox" class="checked__input">
              <span class="checked__square"></span>
              <span class="checked__text">{{item.name}}</span>
            </label>
          </div>

          <div class='interests-row'>
            <label [for]=item.id class="checked" *ngFor='let item of interestsArrays?.array2'>
              <input [id]=item.id (change)="changeInterests($event, item.name)" type="checkbox" class="checked__input">
              <span class="checked__square"></span>
              <span class="checked__text">{{item.name}}</span>
            </label>
          </div>

          <div class='interests-row'>
            <label [for]=item.id class="checked" *ngFor='let item of interestsArrays?.array3'>
              <input [id]=item.id (change)="changeInterests($event, item.name)" type="checkbox" class="checked__input">
              <span class="checked__square"></span>
              <span class="checked__text">{{item.name}}</span>
            </label>
          </div>

        </div>
      </div>
      <img src="assets/img/campaigns/campaign-top-bg.jpg" alt="" class="campaign__bg">
    </div>

    <div class="overview-main"><div class="no-posts__text" *ngIf="noFilteredCampaigns">
      <h3>No campaigns found...</h3></div>
      <div class="wrapper">
      </div>
    </div>

    <div class="wrapper-big">
      <div class="wrapper" *ngIf='!noFilteredCampaigns'>
        <div class="campaign__top">
          <span class="campaign__top-line"></span>
          <span class="campaign__main-title">{{'campaigns.activeTitle' | translate}}</span>
          <span class="campaign__number">{{activeCampaigns.length}}</span>
        </div>
      </div>
      <img src="assets/img/campaigns/campaign-top-bg.jpg" alt="" class="campaign__bg">
    </div>

    <div class="wrapper-big">
      <div class="wrapper">
        <div class="campaign__middle">
          <div class="campaign-box" *ngFor='let campaign of activeCampaigns' (click)='openCampaign(campaign)'>

            <div class="campaign-box__img">
              <img [src]='campaign.heroImageUrl' [alt]="campaign.campaignName">
              <div class="campaign-box__on_review" *ngIf='!campaign.reviewed'>
                {{'campaigns.waitingForReview' | translate}}
              </div>
              <div class="campaign-box__hover">
                <div class="campaign-box__group">
                  <span class="campaign-box__text">{{'campaigns.posts' | translate}}</span>
                  <span class="campaign-box__text"><b>{{campaign.postSubmissions}}</b></span>
                </div>
                <div class="campaign-box__group">
                  <span class="campaign-box__text">{{'campaigns.accepted' | translate}}</span>
                  <span class="campaign-box__text"><b>{{campaign.postsAccepted}}</b></span>
                </div>
                <div class="campaign-box__group">
                  <span class="campaign-box__text" [innerHTML]='"campaigns.openUntil" | translate'></span>
<!--                  <span class="campaign-box__line"></span>-->
                  <span class="campaign-box__text"><b>{{campaign.openUntilDate | date: 'dd/MM/yyyy'}}</b></span>
                </div>
              </div>
            </div>
            <span class="campaign-box__title">{{campaign.campaignName}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="campaign__cont">
    <div class="wrapper-big">
      <div class="wrapper" *ngIf='!noFilteredCampaigns'>
        <div class="campaign__top">
          <span class="campaign__top-line"></span>
          <span class="campaign__main-title">{{'campaigns.completedTitle' | translate}}</span>
          <span class="campaign__number">{{completedCampaigns.length}}</span>
        </div>
      </div>

      <img src="assets/img/campaigns/campaign-top-bg.jpg" alt="" class="campaign__bg">
    </div>

    <div class="wrapper-big">
      <div class="wrapper">
        <div class="campaign__middle">

          <div class="campaign-box" *ngFor='let campaign of completedCampaigns' [routerLink]="[campaign.id]">
            <div class="campaign-box__img">
              <img [src]='campaign.heroImageUrl' [alt]="campaign.campaignName">
              <div class="campaign-box__hover">
                <div class="campaign-box__group">
                  <span class="campaign-box__text">{{'campaigns.posts' | translate}}</span>
                  <span class="campaign-box__text"><b>{{campaign.postSubmissions}}</b></span>
                </div>
                <div class="campaign-box__group">
                  <span class="campaign-box__text">{{'campaigns.accepted' | translate}}</span>
                  <span class="campaign-box__text"><b>{{campaign.postsAccepted}}</b></span>
                </div>
                <div class="campaign-box__group">
                  <span class="campaign-box__text" [innerHTML]='"campaigns.openUntil" | translate'></span>
<!--                  <span class="campaign-box__line"></span>-->
                  <span class="campaign-box__text"><b>{{campaign.openUntilDate | date: 'dd/MM/yyyy'}}</b></span>
                </div>
              </div>
            </div>
            <span class="campaign-box__title">{{campaign.campaignName}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<router-outlet></router-outlet>
