import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, has } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { FeatureConfig } from '@app/shared/dto/feature-flags.dto';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  config: FeatureConfig = null;
  configUrl = `/config/feature-flags.json`;

  constructor(private http: HttpClient) {}

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   */
  loadConfig(): any {
    return this.http
      .get<FeatureConfig>(this.configUrl)
      .pipe(tap((data) => (this.config = data)))
      .toPromise();
  }

  isFeatureEnabled(key: string): any {
    if (this.config && has(this.config, key)) {
      return get(this.config, key, false);
    }
    return false;
  }
}
