import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CommonService } from '@app/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-free-demo',
  templateUrl: './free-demo.component.html',
  styleUrls: ['./free-demo.component.scss'],
})
export class FreeDemoComponent implements OnInit {
  isBrand = false;

  demoForm = this.fb.group(
    {
      company: [''],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      surname: [''],
      city: [''],
      address: [''],
      phone: [''],
      looking_for: [''],
      budget: [''],
      acceptRules: ['', [Validators.required, Validators.requiredTrue]],
    },
    {
      updateOn: 'change',
    }
  );

  constructor(
    public ref: DialogRef,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.isBrand = this.ref.data.brand;
  }

  checkFieldError(fieldName): boolean {
    return !!(!this.demoForm.get(fieldName).valid && this.demoForm.get(fieldName).touched);
  }

  requestFreeDemo(): void {
    this.demoForm.markAllAsTouched();
    if (!this.demoForm.valid) {
      return;
    }

    const successMsg = this.translate.instant('contactUs.successMsg');
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');

    const company = this.demoForm.get('company').value;
    const name = this.demoForm.get('name').value;
    const email = this.demoForm.get('email').value;
    const surname = this.demoForm.get('surname').value;
    const city = this.demoForm.get('city').value;
    const address = this.demoForm.get('address').value;
    const phone = this.demoForm.get('phone').value;
    const budget = this.demoForm.get('budget').value || 0;
    const lookingFor = this.demoForm.get('looking_for').value;

    const requestDemo = {
      company,
      name,
      email,
      surname,
      city,
      address,
      phone, // @ts-ignore
      looking_for: lookingFor,
      agency: !this.isBrand,
      budget,
    } as any;

    this.commonService
      .requestDemo(requestDemo)
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.demoForm.reset();
        this.ref.close();
      });
  }
}
