import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/services/auth.guard';
import { NotFoundComponent } from '@app/common/not-found/not-found.component';
import { HomeComponent } from './landing/home/home.component';
import { CampaignsComponent } from '@app/campaigns/campaigns.component';
import { ProfileComponent } from './profile/profile.component';
import { CampaignOverviewComponent } from '@app/campaigns/campaign-overview/campaign-overview.component';
import { CampaignComponent } from '@app/campaign/campaign.component';
import { BriefStepComponent } from '@app/campaign/brief-step/brief-step.component';
import { CampaignStepComponent } from '@app/campaign/campaign-step/campaign-step.component';
import { CampaignDetailsComponent } from '@app/campaigns/campaign-overview/campaign-details/campaign-details.component';
import { InfluencersStepComponent } from '@app/campaign/influencers-step/influencers-step.component';
import { ReviewStepComponent } from '@app/campaign/review-step/review-step.component';
import { SubmitStepComponent } from '@app/campaign/submit-step/submit-step.component';
import { CanDeactivateGuard } from '@app/services/can-deactivate.guard';
import { AnalyticsComponent } from '@app/campaigns/campaign-overview/analytics/analytics.component';
import { PostFeedbackComponent } from '@app/common/post-feedback/post-feedback.component';
import { ChangePasswordComponent } from '@app/landing/change-password/change-password.component';
import { InsightsComponent } from '@app/campaigns/campaign-overview/insights/insights.component';
import { LoginComponent } from '@app/landing/login/login.component';
import { PostTabComponent } from '@app/campaigns/campaign-overview/post-tab/post-tab.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'campaigns',
    canActivate: [AuthGuard],
    component: CampaignsComponent,
    loadChildren: () => import('@app/campaigns/campaigns.module').then((m) => m.CampaignsModule),
  },
  {
    path: 'campaigns/:id',
    canActivate: [AuthGuard],
    component: CampaignOverviewComponent,
    children: [
      { path: '', pathMatch: 'full', component: CampaignDetailsComponent },
      { path: 'analytics', pathMatch: 'full', component: AnalyticsComponent },
      {
        path: 'post/:id',
        component: PostTabComponent,
      },
      {
        path: 'edit',
        component: CampaignComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [
          { path: '', pathMatch: 'full', component: CampaignStepComponent },
          {
            path: 'brief',
            component: BriefStepComponent,
          },
          {
            path: 'influencers',
            component: InfluencersStepComponent,
          },
          {
            path: 'review',
            component: ReviewStepComponent,
          },
          {
            path: 'submit',
            component: SubmitStepComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'insights/:id',
    canActivate: [AuthGuard],
    component: InsightsComponent,
  },
  {
    path: 'services/feedback/:id/:email',
    component: PostFeedbackComponent,
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', component: CampaignStepComponent },
      {
        path: 'brief',
        component: BriefStepComponent,
      },
      {
        path: 'influencers',
        component: InfluencersStepComponent,
      },
      {
        path: 'review',
        component: ReviewStepComponent,
      },
      {
        path: 'submit',
        component: SubmitStepComponent,
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    loadChildren: () =>
      import('@app/landing/change-password/change-password.module').then((m) => m.ChangePasswordModule),
  },
  { path: 'login', component: LoginComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
