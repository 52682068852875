<div class="modal-block modal-brand-login">
  <div class="modal__cont">
    <span class="modal__title">{{'inviteUser.inviteUserTitle' | translate }}</span>

    <form [formGroup]="inviteUserForm" class="form form--w-740" style="flex-direction: column">

      <div class="select-box">
        <div class="form__box"
             [class.error]="inviteUserForm.get('name').invalid && inviteUserForm.get('name').touched">
          <span class="form__title">{{'myProfile.firstName' | translate }}</span>
          <label>
            <input type="text" class="form__input" formControlName="name">
          </label>
          <span class="form__error"
                *ngIf="!inviteUserForm.get('name').valid && inviteUserForm.get('name').touched">
            {{'newCampaign.submitMsg.requiredFieldError' | translate}}
          </span>
        </div>
      </div>

      <div class="select-box">
        <div class="form__box"
             [class.error]="inviteUserForm.get('surname').invalid && inviteUserForm.get('surname').touched">
          <span class="form__title">{{'myProfile.lastName' | translate }}</span>
          <label>
            <input type="text" class="form__input" formControlName="surname">
          </label>
          <span class="form__error"
                *ngIf="!inviteUserForm.get('surname').valid && inviteUserForm.get('surname').touched">
            {{'newCampaign.submitMsg.requiredFieldError' | translate}}
          </span>
        </div>
      </div>


      <div class="form__box"
           [class.error]="inviteUserForm.get('email').invalid && inviteUserForm.get('email').touched">
        <span class="form__title">Email</span>
        <label>
          <input type="email" class="form__input" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" formControlName="email">
        </label>
        <span *ngIf="!inviteUserForm.get('email')?.errors?.notEquivalent"
              class="form__error">{{'newCampaign.submitMsg.requiredFieldError' | translate}}</span>
      </div>

      <button (click)="inviteUser()" type="submit" [disabled]="!inviteUserForm.valid"
              class="btn big primary-btn-filled">
        <span class="btn__text">{{'inviteUser.inviteUserButton' | translate }}</span>
      </button>

    </form>
  </div>

</div>
