import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { selectIsLoaded, UserState } from '@app/store';
import { logOutUser } from '@store/app.actions';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/app.state';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent implements OnInit {
  isLoggedIn = false;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectIsLoaded)).subscribe((user) => {
      this.isLoggedIn = !!user;
    });

    this.isLoggedIn = !!this.authService.isLoggedIn;
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  logout(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    this.store.dispatch(logOutUser());
    this.router.navigate(['/']);
  }
}
