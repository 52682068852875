import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectCampaignDraft } from '@app/store';
import { CampaignModel } from '@app/shared/model';
import { AppState } from '@store/app.state';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as campaignDraftActions from '@store/campaign-draft';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campaign-wizard-step',
  templateUrl: './campaign-wizard.component.html',
  styleUrls: ['./campaign-wizard.component.scss'],
})
export class CampaignWizardComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  @Input() stepTitle: string;
  @Input() enabledNextStep: boolean;
  @Output() previousStepClicked = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter();

  steps: {
    campaign: false;
    brief: false;
    influencers: false;
    review: false;
    submit: false;
  };

  nextButtonText = '';
  editCampaignName = 'create new campaign';
  submitCampaignProcess = false;
  isEdit = false;
  reviewStep = false;
  private campaignMoodImages = [];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.log('init');
    this.steps = {
      ...this.steps,
      [this.stepTitle]: true,
    };

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.isEdit = true;
      this.store
        .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
        .subscribe((campaignDraft: CampaignModel) => {
          this.editCampaignName = campaignDraft?.campaignName;
        });
    }
    if (this.router.routerState.snapshot.url.includes('review')) {
      this.reviewStep = true;
    }
    this.subscribeSubmitCampaign();
    this.subscribeSubmitCampaignSuccess();
    this.subscribeSubmitCampaignFail();

    this.translate
      .get('newCampaign.review.submitBtn')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const nextButton = this.translate.instant('newCampaign.review.nextBtn');
        const submitButton = this.translate.instant('newCampaign.review.submitBtn');
        this.nextButtonText = this.stepTitle === 'submit' ? submitButton : nextButton;
        if (!this.isEdit) {
          this.editCampaignName = this.translate.instant('newCampaign.newCampaignTitle');
        }

        if (!this.isEdit && this.reviewStep) {
          this.editCampaignName = this.translate.instant('newCampaign.reviewCampaignTitle');
        }
      });
  }
  subscribeSubmitCampaign(): void {
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmit), takeUntil(this.destroyed$))
      .subscribe(({ campaign }) => {
        if (campaign.moodImages?.length) {
          this.campaignMoodImages = campaign.moodImages.slice();
        }
        this.submitCampaignProcess = true;
      });
  }
  subscribeSubmitCampaignSuccess(): void {
    const submitCampaignSuccess = this.translate.instant('newCampaign.submitMsg.submitEditMsgSuccess');
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitSuccess), take(1), takeUntil(this.destroyed$))
      // @ts-ignore
      .subscribe(({ campaign }) => {
        this.campaignMoodImages.forEach(({ image }) => {
          this.store.dispatch(campaignDraftActions.addCampaignMoodImage({ campaignId: campaign.id, image }));
        });
        this.submitCampaignProcess = false;
        this.toastr.success(submitCampaignSuccess);
        this.router.navigate(['campaigns']);
      });
  }
  subscribeSubmitCampaignFail(): void {
    const submitCampaignFail = this.translate.instant('newCampaign.submitMsg.submitEditMsgError');
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitFail), takeUntil(this.destroyed$))
      .subscribe((error) => {
        console.log(error);
        this.submitCampaignProcess = false;
        this.toastr.error(submitCampaignFail);
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  goToPreviousStep(): void {
    this.previousStepClicked.emit();
  }

  goToNextStep(): void {
    this.nextStepClicked.emit();
  }
}
