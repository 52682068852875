import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { PostEditingDialogComponent } from '@app/dialogs/post-editing-dialog/post-editing-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PostEditingDialogComponent],
  imports: [CommonModule, SwiperModule, TranslateModule],
  exports: [PostEditingDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PostEditingDialogModule {}
