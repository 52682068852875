import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TopBarModule } from './top-bar/top-bar.module';
import { FooterModule } from './footer/footer.module';
import { LoginDialogModule } from '@app/landing/dialogs/login/login-dialog/login-dialog.module';

@NgModule({
  declarations: [],
  imports: [RouterModule, CommonModule, BrowserModule, TopBarModule, FooterModule, LoginDialogModule],
  exports: [TopBarModule, FooterModule],
})
export class CommonUIModule {}
