import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { DialogService } from '@ngneat/dialog';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';
import { select, Store } from '@ngrx/store';
import { selectIsLoaded } from '@app/store';
import { AppState } from '@store/app.state';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  public selectedLanguage;
  isLoggedIn = false;
  socialLinks = CONFIG.platform.socialLinks;
  isFeedbackPage = false;

  constructor(
    private authService: AuthService,
    private dialog: DialogService,
    private store: Store<AppState>,
    private router: Router,
    private location: Location,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = !!this.authService.isLoggedIn;

    this.store.pipe(select(selectIsLoaded)).subscribe((user) => {
      this.isLoggedIn = !!user;
    });

    this.router.events.subscribe(() => {
      this.isFeedbackPage = !!this.location.path().includes('services/feedback');
    });
    this.setLanguage();
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      closeButton: true,
    });
  }

  setLanguage(): void {
    const { defaultLanguage } = CONFIG.platform;
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use('it');
  }
}
