import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-post-decline-change',
  templateUrl: './post-decline-change.component.html',
})
export class PostDeclineChangeComponent implements OnInit {
  declineReason: string;
  declineReasonsModel = 'default';
  declineReasons: string[];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.declineReasons = this.translate.instant('campaignOverview.post.dialog.declineReasons');
  }

  reasonChange(event): void {
    this.declineReason = event.target.value;
  }
}
