import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { userReducer, UserEffects, UserState } from './user';
import { BrandEffects, brandReducer } from './brand';
import { CampaignsEffects, campaignsReducer } from '@store/campaigns';
import { CampaignOverviewEffects, campaignOverviewReducer } from '@store/campaign-overview';
import * as appActions from './app.actions';
import { CampaignDraftEffects, campaignDraftReducer } from '@store/campaign-draft';

export * from './user';
export * from './brand';
export * from './campaigns';
export * from './campaign-overview';
export * from './campaign-draft';

export const RESET_STATE = Symbol('reset state');

export const appReducer = {
  user: userReducer,
  brand: brandReducer,
  campaigns: campaignsReducer,
  campaignOverview: campaignOverviewReducer,
  campaignDraft: campaignDraftReducer,
};

export const appEffects = [UserEffects, BrandEffects, CampaignsEffects, CampaignOverviewEffects, CampaignDraftEffects];

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action != null && action.type === appActions.AppActionTypes.LOGOUT_USER) {
      return reducer(RESET_STATE, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [logout];
