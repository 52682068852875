import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostReviewChangeComponent } from './post-review-change.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@ngneat/dialog';

@NgModule({
  declarations: [PostReviewChangeComponent],
  imports: [CommonModule, FormsModule, TranslateModule, DialogModule],
  exports: [PostReviewChangeComponent],
})
export class PostReviewChangeModule {}
