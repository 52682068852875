import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostTabComponent } from '@app/campaigns/campaign-overview/post-tab/post-tab.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PostReviewChangeModule } from '@app/dialogs/post-review-change/post-review-change.module';
import { PostDeclineChangeModule } from '@app/dialogs/post-decline-change/post-decline-change.module';
import { PostEditingDialogModule } from '@app/dialogs/post-editing-dialog/post-editing-dialog.module';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [PostTabComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    PostReviewChangeModule,
    PostDeclineChangeModule,
    PostEditingDialogModule,
    RouterModule,
  ],
  exports: [PostTabComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PostTabModule {}
