import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-interests',
  templateUrl: './select-interests.component.html',
  styleUrls: ['./select-interests.component.scss']
})
export class SelectInterestsComponent implements OnInit {
  @Input() selectInterests: string[] = [];
  @Output() selectInterestsChange = new EventEmitter();
  @Input() linkedinInterests: string[];
  @Input() isTabs = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleInterests(interest: string): void {
    const index = this.selectInterests.indexOf(interest);
    if (this.selectInterests.includes(interest)) {
      this.selectInterests.splice(index, 1);
    } else {
      this.selectInterests.push(interest);
    }
    this.selectInterestsChange.emit(this.selectInterests);
  }

  checkInterest(interest: string): boolean {
    return this.selectInterests.includes(interest);
  }

  getKeyNameForTranslate(name): string {
    return name.toLowerCase().split(',')[0].split(' ')[0];
  }

}
