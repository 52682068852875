import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectCampaignDraft, selectPrivate, selectPrivateInfluencers } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { CampaignModel, SocialPlatforms } from '@app/shared/model';
import { Subject, Observable } from 'rxjs';
import * as campaignDraftActions from '@store/campaign-draft';
import { CONFIG } from '@config/configuration';
import { FeatureFlagsService } from '@app/services/feature-flag.service';

@Component({
  selector: 'app-influencers-step',
  templateUrl: './influencers-step.component.html',
  styleUrls: ['./influencers-step.component.scss'],
})
export class InfluencersStepComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  stepTitle = 'influencers';

  options: Options = {
    floor: 16,
    ceil: 80,
  };

  age = {
    ageMin: 16,
    ageMax: 80,
  };

  gender = {
    male: false,
    female: false,
  };

  targetRegion: any[] = [];
  interests: string[] = [];
  selectedLinkedinInterests: string[] = [];

  isPrivateInfluencersFeatureEnabled = false;
  isPrivate = false;
  privateInfluencers: any[] = [];
  selectedInfluencers: string[] = [];
  socialPlatform: SocialPlatforms;
  selectedSocialPlatform = [];
  linkedinInterests = [
    'Agriculture - Livestock - Fishing',
    'Industry - Handicraft - Construction - Chemistry - Energy',
    'Food processing',
    'Services - Consulting',
    'Banking Insurance Financial',
    'Medical - Health - Health',
    'Catering - Hotels - Tourism',
    'Wholesale import export retail trade',
    'Informatics - Software - Internet',
    'Communication, Marketing, Digital, Social Media Agencies',
    'NGO, Non-Profit',
    'Public Administration - Education'
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.isPrivateInfluencersFeatureEnabled = this.featureFlagService.isFeatureEnabled('privateInfluencers');
    this.targetRegion = CONFIG.platform.countries;

    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        if (campaignDraft) {
          this.interests = [...campaignDraft.interests];
          if (campaignDraft.linkedinInterests) {
            this.selectedLinkedinInterests = [...campaignDraft.linkedinInterests];
          }
          this.setGender(campaignDraft.gender);
          this.setDemographic(campaignDraft.infoDemographic);
          this.setAge(campaignDraft.ageMax, campaignDraft.ageMin);
          this.socialPlatform = campaignDraft.socialPlatforms;

          if (campaignDraft.privateInfluencers && campaignDraft.privateInfluencers.length) {
            this.selectedInfluencers = campaignDraft.privateInfluencers;
          }

          this.setSelectedSocialPlatform();
        }
      });

    this.store.pipe(select(selectPrivate), takeUntil(this.destroyed$)).subscribe((isPrivate: boolean) => {
      this.isPrivate = isPrivate;
    });
    this.store.pipe(select(selectPrivateInfluencers), takeUntil(this.destroyed$)).subscribe((influencers: any[]) => {
      this.privateInfluencers = influencers;
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setSelectedSocialPlatform(): void {
    Object.keys(this.socialPlatform).map((item) => {
      if (this.socialPlatform[item]) {
        this.selectedSocialPlatform.push(item);
      }
    });
  }

  setGender(gender: string): void {
    if (gender === 'all') {
      this.gender.male = true;
      this.gender.female = true;
    } else {
      this.gender[gender] = true;
    }
  }

  setAge(ageMax: number, ageMin): void {
    this.age = {
      ageMin,
      ageMax,
    };
  }

  setDemographic(demographic: string): void {
    if (!demographic.length) {
      return;
    }
    const countries = demographic.split(',');
    countries.forEach((country) => {
      this.targetRegion.map((region) => {
        if (region.country_name === country) {
          region.active = true;
        }
      });
    });
  }

  isInterestsSelected(): boolean {
    if (
      this.selectedSocialPlatform.length >= 1 && !this.socialPlatform.platformLinkedin ||
      this.selectedSocialPlatform.length >= 2 && this.socialPlatform.platformLinkedin
    ) {
      return !!this.interests.length;
    }
    return true;
  }

  enableNextStep(): boolean {
    return ((
        this.isInterestsSelected() &&
        ((this.socialPlatform?.platformLinkedin) ? !!this.selectedLinkedinInterests.length : true) &&
        (this.gender.male || this.gender.female) &&
        this.targetRegion.filter((e) => e.active === true).length > 0) ||
      (this.isPrivateInfluencersFeatureEnabled && this.isPrivate && !!this.selectedInfluencers.length));
  }

  changeGender(gender: string): void {
    this.gender[gender] = !this.gender[gender];
  }
  goToNextStep(): void {
    this.saveData();
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }
  saveData(): void {
    let gender;
    const { ageMax, ageMin } = this.age;
    const { interests } = this;

    if (!this?.socialPlatform?.platformLinkedin && this.selectedLinkedinInterests.length >= 1) {
      this.selectedLinkedinInterests = null;
    }

    const linkedinInterests = this.selectedLinkedinInterests;
    const demographic = [];
    let infoDemographic = '';

    this.targetRegion.forEach((region) => {
      if (region.active === true) {
        demographic.push(region.country_name);
      }
    });

    infoDemographic = demographic.join(',');

    if (this.gender.female && this.gender.male) {
      gender = 'all';
    } else {
      gender = this.gender.female ? 'female' : 'male';
    }

    const campaign = {
      gender,
      ageMax,
      ageMin,
      interests,
      infoDemographic,
      linkedinInterests
    } as Partial<CampaignModel>;

    if (this.isPrivateInfluencersFeatureEnabled && this.isPrivate && this.selectedInfluencers.length) {
      campaign.privateInfluencers = this.selectedInfluencers;
    }

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
  }

  goToPreviousStep(): void {
    this.saveData();
    this.router.navigate(['../brief'], { relativeTo: this.activatedRoute });
  }

  onUpdateInfluencers(arr: string[]) {
    this.selectedInfluencers = arr;
    console.log(arr);
    // TODO: add influencers list to request
  }
}
