import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { HelperService } from '@app/services/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  resultMessage: string;
  accessToken: string;
  verificationCode: string;

  changePasswordForm = this.fb.group(
    {
      password: ['', [Validators.required]],
      confirm: ['', [Validators.required]],
    },
    {
      updateOn: 'change',
      validators: [this.checkIfMatchingPasswords('password', 'confirm')],
    }
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private helperService: HelperService,
    private toastr: ToastrService
  ) {}

  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return this.helperService.checkIfMatchingPasswords(passwordKey, passwordConfirmationKey);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.accessToken = params.ac;
      this.verificationCode = params.c;
    });
  }

  changePassword(): void {
    const successMsg = this.translate.instant('activateInfluencer.success');
    const failMsg = this.translate.instant('commonMsg.somethingWentWrong');
    const password = this.changePasswordForm.get('password').value;

    this.commonService
      .changePassword(this.accessToken, this.verificationCode, password)
      .pipe(
        catchError(({ error }) => {
          // TODO: refactor on API side
          if (error.text === 'True') {
            this.toastr.success(successMsg);
          } else {
            this.toastr.error(failMsg);
            return throwError(error);
          }
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
      });
  }
}
