<div class="modal-wallet">
  <div class="modal__cont">
    <span class="modal__title">{{"paypalWallet.dialog.title" | translate}}</span>

    <form [formGroup]="paymentForm" >
    <div class="modal-wallet__bar">
      <div class="modal-wallet__cont">
        <div class="modal-wallet__colum">
          <span class="modal-wallet__title" [innerHTML]="'paypalWallet.dialog.funds' | translate"></span>
          <span class="modal-wallet__price"><b>{{currencyName}}</b> {{(brand$ | async)?.funds}}</span>
        </div>
        <div class="modal-wallet__colum">
          <div class="form__box" [ngClass]='{"error": paymentForm.get("amount").invalid && paymentForm.get("amount").touched}'>
            <span class="form__title">{{"paypalWallet.dialog.topup" | translate}}</span>
            <input formControlName='amount' type="number" class="form__input">
          </div>
        </div>
      </div>

      <img src="assets/img/modal-wallet__bg.jpg" alt="" class="modal-wallet__bg">
    </div>

    <div class="modal-wallet__cont"> <!-- {{currencyName}} 50 + additional {{currencyName}} {{getFeeAmount()}} PayPal fee -->
      <div class="modal-wallet__row">
        <span class="modal-wallet__text">{{"paypalWallet.dialog.tip1" | translate: {currencyName: currencyName, amount: getFeeAmount()} }}</span>
        <span class="modal-wallet__text">{{"paypalWallet.dialog.tip2" | translate: {currencyName: currencyName} }}</span>
      </div>

      <span class="modal-wallet__text">{{"paypalWallet.dialog.tip3" | translate: {currencyName: currencyName} }}</span>
      <div class='pay-pal-wrapper'>
        <div id="paypal-button"></div>
      </div>
    </div>
    </form>
  </div>

<!--  <div class="modal-close" (click)='ref.close()'>-->
<!--    <svg width="45" height="45" viewBox="0 0 45 45" fill="currentColor">-->
<!--      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45ZM22.5 40.9091C32.667 40.9091 40.9091 32.6671 40.9091 22.5C40.9091 12.333 32.667 4.09092 22.5 4.09092C12.3329 4.09092 4.09087 12.333 4.09087 22.5C4.09087 32.6671 12.3329 40.9091 22.5 40.9091ZM15.7645 32.1282L22.5 25.3927L29.2354 32.1282L32.1281 29.2355L25.3927 22.5L32.1281 15.7646L29.2354 12.8719L22.5 19.6073L15.7645 12.8719L12.8718 15.7646L19.6073 22.5L12.8718 29.2355L15.7645 32.1282Z"/>-->
<!--    </svg>-->
<!--  </div>-->
</div>
