import { UserDto } from '@app/shared/dto';
import { UserModel } from '@app/shared/model';

export class UserAdapter {
  public static createUser(userResponse: UserDto): UserModel {
    if (!userResponse) {
      return null;
    }

    const model = new UserModel();

    model.email = userResponse.email;
    model.firstName = userResponse.first_name;
    model.id = userResponse.id;
    model.invoice = userResponse.invoice;
    model.isActive = userResponse.is_active;
    model.isAdditionalBrandUser = userResponse.is_additional_brand_user;
    model.isBrand = userResponse.is_brand;
    model.isFirstLogin = userResponse.is_first_login;
    model.isBrandOwner = userResponse.is_brand_owner;
    model.lastName = userResponse.last_name;
    model.username = userResponse.username;

    return model;
  }
}
