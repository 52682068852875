import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-submit-step',
  templateUrl: './submit-step.component.html',
  styleUrls: ['./submit-step.component.scss'],
})
export class SubmitStepComponent implements OnInit {
  isLoggedIn = false;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isLoggedIn = !!this.authService.isLoggedIn;
  }
}
