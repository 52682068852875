import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagDirective } from '@app/shared/directives/feature-flag-directive/feature-flag.directive';

@NgModule({
  declarations: [FeatureFlagDirective],
  imports: [CommonModule],
  exports: [FeatureFlagDirective],
})
export class FeatureFlagDirectiveModule {}
